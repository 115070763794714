import { CustomDataGrid, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useMemo, useRef, useState } from "react"
import { Box, Button, Switch } from "@mui/material"
import { setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { dispatch, useSelector } from "../../../redux/store"
import { invoicingTypeAtom } from "../../../utils/atom"
import { useSetAtom } from "jotai"
import NoDataPage from "../../../components/NoDataPage"
import { GridColDef } from "@mui/x-data-grid-pro"
import { InvoicingItem } from "../../../queries/invoicing-configuration"
import { fetchEventSource } from "@microsoft/fetch-event-source"
import Api from "../../../api/Api"
import { useSnackbar } from "notistack"
import { LoadingButton } from "@mui/lab"
import { DateTime } from "luxon"

export default function InvoicingView() {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const setInvoicingType = useSetAtom(invoicingTypeAtom)
    const { enqueueSnackbar } = useSnackbar()
    const [generatedInvoices, setGeneratedInvoices] = useState<InvoicingItem[]>([])
    const [isGeneratingInvoices, setIsGeneratingInvoices] = useState(false)
    const isCreatingInvoices = useRef(false)

    const closeModal = useCallback(() => {
        setInvoicingType(undefined)
        dispatch(setEditingItem(undefined))
        dispatch(setModalOpen(undefined))
    }, [modalOpen, dispatch, isMobile])

    const columns = useMemo(() => {
        const columns: GridColDef<InvoicingItem>[] = [
            {
                field: "propertyName",
                type: "string",
                minWidth: 120,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("propertyName"),
            },
            {
                field: "invoiceDate",
                type: "date",
                width: 140,
                headerAlign: "center",
                align: "center",
                headerName: translate("date"),
                valueFormatter: (value) => DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "invoice",
                type: "string",
                minWidth: 140,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("invoice_name"),
            },
        ]

        return columns
    }, [translate])

    const onGenerateInvoicesClick = useCallback(() => {
        setGeneratedInvoices([])
        setIsGeneratingInvoices(true)
        fetchEventSource(
            Api.API +
                `/nomenclature/invoicing-configuration/${editingItem.invoicingConfigurationId}/companies/${editingItem.invoiceCompanyId}/generate-invoices?isTest=${!isCreatingInvoices.current}`,
            {
                credentials: "include",
                onopen: async (_: Response) => {
                    setGeneratedInvoices([])
                },
                onmessage: (ev) => {
                    if (ev.data.startsWith("error_")) {
                        enqueueSnackbar(ev.data.replace("error_", ""), { variant: "error" })
                    } else {
                        setGeneratedInvoices((prev) => [...prev, JSON.parse(ev.data)])
                    }
                },
                onclose: async () => {
                    setIsGeneratingInvoices(false)
                },
                onerror: (_) => {
                    setIsGeneratingInvoices(false)
                },
            },
        )
    }, [enqueueSnackbar])

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingX: 0,
                    paddingTop: 2,
                    gap: 2,
                    flexDirection: isMobile ? "column-reverse" : "row",
                }}
            >
                <Box display='flex' flexDirection='row' alignItems='center'>
                    {translate("is_testing_invoice")}
                    <Switch defaultChecked={false} onChange={(_, v) => (isCreatingInvoices.current = v)} title={translate("is_testing_invoice")} />
                </Box>
                <LoadingButton onClick={onGenerateInvoicesClick} loading={isGeneratingInvoices} color='primary' variant='contained'>
                    {translate("generate_invoices")}
                </LoadingButton>
            </Box>
            <CustomDataGrid
                gridId='InvoicingView'
                columns={columns}
                rows={generatedInvoices ?? []}
                idKey={"propertyName"}
                getRowId={(row) => row.propertyName}
                components={{
                    noRowsOverlay: () => <NoDataPage onAddNewItem={undefined} icon={""} noDataText={"no_invoices"} />,
                }}
                rowHeight={50}
                gridHeight={400}
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingX: 0,
                    paddingTop: 2,
                    gap: 2,
                    flexDirection: isMobile ? "column-reverse" : "row",
                }}
            >
                <Button color={"primary"} onClick={closeModal}>
                    {translate("cancel")}
                </Button>
            </Box>
        </>
    )
}
