import { ExpenseAssignee } from "rentzz"
import { RecurringType } from "../../queries/expenses"
import { PaymentSubtype, PaymentType } from "../../redux/slices/AddProperty"

export const getAssigneeLabelColor = (value: ExpenseAssignee | undefined) => {
    switch (value) {
        case ExpenseAssignee.ME:
            return "secondary"
        case ExpenseAssignee.TENANT:
            return "primary"
    }
}

export const getRecurringTypeLabelColor = (value: RecurringType) => {
    switch (value) {
        case RecurringType.Daily:
            return "secondary"
        case RecurringType.Weekly:
            return "info"
        case RecurringType.Monthly:
            return "primary"
    }
}

export const getRecurringTypeLabelText = (value: RecurringType, currentLang: string, long: boolean) => {
    if (long) {
        switch (value) {
            case RecurringType.Daily:
                return "recurring_label_daily_long"
            case RecurringType.Weekly:
                return "recurring_label_weekly_long"
            case RecurringType.Monthly:
                return "recurring_label_monthly_long"
        }
    }
    switch (value) {
        case RecurringType.Daily:
            return "recurring_label_daily_short"
        case RecurringType.Weekly:
            return "recurring_label_weekly_short"
        case RecurringType.Monthly:
            return "recurring_label_monthly_short"
    }
}

export enum ExpensePaymentStatus {
    NotPaid,
    Paid,
    Overpaid,
}
export const getExpensePaymentStatus = (expenseValue: number | null, amountPaid: number, percent: number) => {
    if (expenseValue == null) return
    const difference = Math.abs(expenseValue - amountPaid)
    const maxDifference = expenseValue * percent

    if (expenseValue > amountPaid && Math.abs(expenseValue - amountPaid) > maxDifference) {
        return ExpensePaymentStatus.NotPaid
    }
    if (difference > maxDifference && amountPaid > expenseValue) {
        return ExpensePaymentStatus.Overpaid
    }
    if (difference <= maxDifference) {
        return ExpensePaymentStatus.Paid
    }
}

export const getExpenseTypeIcon = (type: PaymentType, subtype: PaymentSubtype) => {
    if (type === PaymentType.Damages) return "mdi:image-broken-variant"
    if (type === PaymentType.Fees) return "mdi:money-100"
    if (type === PaymentType.Rent) return "mdi:key-chain-variant"
    if (type === PaymentType.SecurityDeposit) return "mdi:instant-deposit"
    if (type === PaymentType.Expense) {
        if (subtype === PaymentSubtype.None) return "material-symbols:wallet"
        if (subtype === PaymentSubtype.Association) return "mdi:office-building-cog"
        if (subtype === PaymentSubtype.Electricity) return "mdi:electricity-circle"
        if (subtype === PaymentSubtype.Gas) return "mdi:gas-circle"
        if (subtype === PaymentSubtype.Parking) return "mdi:parking"
        if (subtype === PaymentSubtype.Sanitation) return "mdi:trash-can-circle"
        if (subtype === PaymentSubtype.Water) return "mdi:water-circle"
    }
    throw new Error(`Invalid type/subtype ${type}/${subtype}`)
}
