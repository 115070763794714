import {
    AccountType,
    RoleName,
    UserFlags,
    useTenantRentingPeriodsQuery,
    useUserDataQueryFn,
    useUserNotificationsQuery,
    useUserPropertiesQuery,
} from "../queries/userData"
import { useDispatch, useSelector } from "../redux/store"
import { useCurrencyQueryFn } from "../queries/currency"
import { useUnitsQueryFn } from "../queries/units"
import { deleteUserProfilePhoto, updateUser, updateUserCurrency, uploadUserProfilePhoto, useLogout } from "../mutations/user"
import { useChangeLanguage } from "../utils/useChangeLanguage"
import * as jsonpatch from "fast-json-patch"
import React, { ReactElement, useCallback, useEffect, useMemo } from "react"
import {
    AppContext,
    DashboardContext,
    DashboardLayout as DBLayout,
    Iconify,
    LayoutOption,
    LoadingScreen,
    Type,
    useLocales,
    UserContextProvider,
    useTypedParams,
} from "rentzz"
import {
    AppModals,
    setContext,
    setCurrentGroupId,
    setCurrentPropertyId,
    setCurrentRentingPeriodId,
    setEditingItem,
    setModalOpen,
} from "../redux/slices/App"
import { useRentingPeriodsWithPaginationQueryFn, useSoonToBeExpiredRentingPeriodsQueryFn } from "../queries/tenants"
import { useTheme } from "@mui/material/styles"
import { tracker } from "../index"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import { PermissionType, usePermissions } from "../hooks/usePermissions"
import PWANotifications from "./PWANotifications"
import Breadcrumbs from "./Breadcrumbs"
import { useSearchQueryFn } from "../queries/search"
import NotificationsDrawerContent from "../sections/notifications/NotificationsDrawerContent"
import { IncomesFilteredOptions } from "../utils/atom"
import { getIncomesReportSummaryQueryFn } from "../queries/income"
import { TenantGuide } from "../sections/user/guide/UserHelpGuard"
import { useSnackbar } from "notistack"
import { getMessaging, onMessage } from "firebase/messaging"
import { DateTime } from "luxon"
import { Alert, Button, Typography } from "@mui/material"
import { useTenantNavConfig } from "./tenant-nav-config"
import { useOwnerNavConfig } from "./owner-nav-config"

interface Props {
    children: ReactElement
}

const DashboardLayout = ({ children }: Props) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { handleLangOption } = useChangeLanguage()
    const { enqueueSnackbar } = useSnackbar()
    const routeCurrentPropertyId = useTypedParams<number | undefined>("currentPropertyId", Type.Number)
    const routeCurrentGroupRouteId = useTypedParams<number | undefined>("currentGroupId", Type.Number)
    const routeCurrentRentingPeriodId = useTypedParams<number | undefined>("currentRentingPeriodId", Type.Number)
    const { context, currentPropertyId } = useSelector((state) => state.appState)
    const { data: user } = useUserDataQueryFn()
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: tenantRentingPeriods } = useTenantRentingPeriodsQuery()
    const { data: soonToBeExpiredRentingPeriods } = useSoonToBeExpiredRentingPeriodsQueryFn()
    const { isLoading: isUnitsLoading } = useUnitsQueryFn()
    const { data: currencies, isLoading } = useCurrencyQueryFn()
    const { mutate: updateCurrency } = updateUserCurrency()
    const { data: rentingPeriods } = useRentingPeriodsWithPaginationQueryFn(0, 0, [])
    const { mutate: signOut } = useLogout()
    const { mutate: updateUserData } = updateUser()
    // const { data: meters } = useMetersQueryFn()
    const policiesPageUrl = useFeatureValue(UserFlags.PoliciesPageUrl.toString(), "")
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())
    const arePropertyAdsEnabled = useFeatureIsOn(UserFlags.PropertyAd.toString())
    const appName = useFeatureValue(UserFlags.Name.toString(), "")
    const showPaymentNotifications = useFeatureIsOn(UserFlags.ShowPaymentNotifications.toString())
    const areTaskItems = useFeatureIsOn(UserFlags.TaskItems.toString())
    const { editableProperties } = usePermissions(PermissionType.Properties)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())
    const { data: userNotifications } = useUserNotificationsQuery()
    const tenantNavConfig = useTenantNavConfig()
    const ownerNavConfig = useOwnerNavConfig()
    const { mutateAsync: uploadProfilePhoto } = uploadUserProfilePhoto()
    const { mutateAsync: deleteProfilePhoto } = deleteUserProfilePhoto()
    const { data: incomesSummaryStatus } = getIncomesReportSummaryQueryFn(true)
    const allGuides = useFeatureValue<Record<string, TenantGuide[]>>(UserFlags.TenantGuides, {})
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const isCurrentRentingPeriodActive = useMemo(() => {
        const currentRentingPeriodDetails = tenantRentingPeriods?.find((rp) => rp.rentingPeriodId === currentRentingPeriodId)
        if (currentRentingPeriodDetails == null) return true

        return (
            context === AppContext.Tenant && currentRentingPeriodDetails?.from <= DateTime.now() && DateTime.now() <= currentRentingPeriodDetails?.to
        )
    }, [context, tenantRentingPeriods])

    useEffect(() => {
        const script = document.createElement("script")
        let isScriptAdded = false

        if (user) {
            if (isWhitelabel && user.accountType === AccountType.WHITELABEL_ADMIN_USER) {
                script.src = "//eu.fw-cdn.com/12853514/823372.js"
                script.async = true
                script.setAttribute("chat", "true")
                isScriptAdded = true
                document.body.appendChild(script)
            }
            tracker.setUserID(user.id)
        } else {
            tracker.setUserID("")
        }

        return () => {
            if (isScriptAdded) document.body.removeChild(script)
        }
    }, [user, appName, isWhitelabel])

    useEffect(() => {
        const messaging = getMessaging()
        if (messaging) {
            onMessage(messaging, (payload) => {
                enqueueSnackbar(payload.data?.body, { variant: "info", preventDuplicate: true, autoHideDuration: 10000 })
            })
        }
    }, [])

    useEffect(() => {
        if (context === AppContext.Owner) {
            dispatch(setCurrentPropertyId(routeCurrentPropertyId))
            dispatch(setCurrentGroupId(routeCurrentGroupRouteId))
            dispatch(setCurrentRentingPeriodId(routeCurrentRentingPeriodId))

            soonToBeExpiredRentingPeriods?.forEach((r) => {
                if (localStorage.getItem(`${r.id}-firstOnRentingPeriodStatusPopup`) === null) {
                    dispatch(setModalOpen(AppModals.RentingPeriodStatus))
                    dispatch(setEditingItem({ rentingPeriodId: r.id }))
                }
            })
        }
    }, [context, dispatch, routeCurrentPropertyId, routeCurrentGroupRouteId, soonToBeExpiredRentingPeriods, routeCurrentRentingPeriodId])

    const getNavConfig = useCallback(() => {
        if (context === AppContext.Tenant) return tenantNavConfig

        return ownerNavConfig
    }, [user, translate, isWhitelabel, arePropertyAdsEnabled, areTaskItems, areInvoicingConfigurationOn, showPaymentNotifications, context])

    const handleCurrencyChange = (currency: { id: number }) => {
        const operations = jsonpatch.compare(
            {
                userPreferences: {
                    currencyId: user?.currency?.id,
                },
            },
            {
                userPreferences: {
                    currencyId: currency.id,
                },
            },
        )
        updateCurrency({
            operations: operations.map((o) => ({ ...o, path: `${o.path}` })),
        })
    }

    const getTooltipIncomeTitle = useCallback(() => {
        if (userProperties?.length === 0) return "no_property_no_income"
        if (rentingPeriods?.count === 0) return "tenant_required_income"
        return ""
    }, [userProperties, rentingPeriods])

    const ownerActions = useMemo(() => {
        return [
            {
                name: "expenses.addExpense",
                icon: "mdi:tag-plus",
                onClick: () => dispatch(setModalOpen(AppModals.AddExpense)),
                isDisabled: userProperties?.length === 0,
                disabledText: "no_property_no_expense",
            },
            {
                name: "recurringExpenses.add_recurring_expense",
                icon: "mdi:tag-plus",
                onClick: () => dispatch(setModalOpen(AppModals.AddRecurringExpense)),
                isDisabled: userProperties?.length === 0,
                disabledText: "no_property_no_expense",
            },
            {
                name: "income-table.addIncome",
                icon: "mdi:cash-plus",
                onClick: () => dispatch(setModalOpen(AppModals.AddIncome)),
                isDisabled: userProperties?.length === 0 || rentingPeriods?.count === 0,
                disabledText: getTooltipIncomeTitle(),
            },
            {
                name: "add_document",
                icon: "mdi:file-document-plus",
                onClick: () => dispatch(setModalOpen(AppModals.AddDocument)),
                isDisabled: userProperties?.length === 0,
                disabledText: "no_property_no_document",
            },
        ]
    }, [dispatch, rentingPeriods, context])

    const tenantActions = useMemo(
        () => [
            {
                name: "payment.addPayment",
                icon: "mdi:database-plus",
                onClick: () => dispatch(setModalOpen(AppModals.AddPayment)),
                isDisabled: false,
                disabledText: "",
            },
            // {
            //     name: "add_reading",
            //     icon: "mdi:timer-plus-outline",
            //     onClick: console.log,
            //     isDisabled: meters?.length === 0,
            //     disabledText: "no_meters_no_reading",
            //     subOptions: currentMeters,
            // },
        ],
        [dispatch],
    )

    const options = useMemo((): LayoutOption[] => {
        const toReturn = [
            {
                icon: <Iconify icon={"mdi:help"} />,
                label: translate("help"),
                hasDivider: true,
                onClick: () => dispatch(setModalOpen(AppModals.Help)),
                color: theme.palette.primary.dark,
                href: "",
            },
            {
                icon: <Iconify icon={"mdi:cog"} />,
                label: translate("settings"),
                hasDivider: true,
                href: "/settings",
                color: theme.palette.primary.dark,
            },
            {
                icon: <Iconify icon={"mdi:email"} />,
                label: translate("contact"),
                hasDivider: true,
                color: theme.palette.primary.dark,
                onClick: () => dispatch(setModalOpen(AppModals.Contact)),
                href: "",
            },
            {
                icon: <Iconify icon={"mdi:account-reactivate"} />,
                label: translate("change_context"),
                hasDivider: true,
                onClick: () => {
                    dispatch(setContext(undefined))
                    dispatch(setCurrentGroupId(undefined))
                    dispatch(setCurrentRentingPeriodId(undefined))
                },
                color: theme.palette.primary.dark,
                href: "",
            },
            {
                icon: <Iconify icon={"mdi:text-box-outline"} />,
                label: translate("policies"),
                hasDivider: true,
                onClick: () => window.open(policiesPageUrl),
                color: theme.palette.primary.dark,
                href: "",
            },
            {
                icon: <Iconify icon={"mdi:logout"} />,
                label: translate("logout"),
                hasDivider: false,
                onClick: async () => {
                    dispatch(setContext(undefined))
                    dispatch(setCurrentPropertyId(undefined))
                    dispatch(setCurrentRentingPeriodId(undefined))
                    signOut()
                },
                color: theme.palette.error.main,
                href: "",
            },
        ]

        if (isWhitelabel) {
            toReturn.splice(0, 4, toReturn[0], toReturn[1], toReturn[2])
        }
        if (context === AppContext.Owner || (allGuides && allGuides["ro"].length === 0)) {
            toReturn.splice(0, 1)
        }
        if (policiesPageUrl.length === 0) {
            toReturn.slice(
                toReturn.findIndex((i) => i.label === translate("policies")),
                1,
            )
        }
        return toReturn
    }, [translate, signOut, dispatch, policiesPageUrl, isWhitelabel, context, allGuides])

    const handlePhotoUpload = useCallback(async (files: FileList) => {
        await deleteProfilePhoto()
        await uploadProfilePhoto({ file: files!.item(0)! })
    }, [])

    const handleColumnReorder = useCallback(
        (gridId: string, columnOrder: string[]) => {
            if (user == null) return
            const currentGridOrdering = user?.columnOrderByTablePreference

            updateUserData({
                newColumnsOrder: { ...(currentGridOrdering ?? {}), [gridId]: columnOrder },
                operations: jsonpatch
                    .compare(
                        { columnOrderByTablePreference: JSON.stringify(currentGridOrdering ?? {}) },
                        { columnOrderByTablePreference: JSON.stringify({ ...currentGridOrdering, [gridId]: columnOrder }) },
                    )
                    .map((o) => ({ ...o, path: `UserPreferences${o.path}` })),
            })
        },
        [user],
    )

    const userDataConfig = useMemo((): DashboardContext | null => {
        if (
            user == null ||
            currencies == null ||
            context == null ||
            (tenantRentingPeriods == null && context == AppContext.Tenant) ||
            ((userNotifications == null || incomesSummaryStatus == null || soonToBeExpiredRentingPeriods == null) && context === AppContext.Owner)
        )
            return null

        return {
            firstName: user.firstName,
            lastName: user.lastName,
            fullName: `${user?.firstName} ${user?.lastName}`,
            email: user.email ?? "",
            currency: user.currency,
            availableCurrencies: currencies,
            onCurrencyChange: handleCurrencyChange,
            onLanguageChange: handleLangOption,
            isTrial: user?.roles.isTrial,
            options,
            canTrial: user.canTrial,
            useSearchQuery: context === AppContext.Owner ? useSearchQueryFn : undefined,
            isExpired: user?.isSubscriptionExpired,
            numberOfNotifications:
                (userNotifications?.pendingExpensesToAccept ?? 0) +
                (userNotifications?.pendingReadingsToSend ?? 0) +
                ((incomesSummaryStatus ?? [])[IncomesFilteredOptions.Pending]?.count ?? 0) +
                (soonToBeExpiredRentingPeriods?.length ?? 0),
            showTrialAlerts: context === AppContext.Owner,
            isChoosePropertiesDisabled: tenantRentingPeriods?.length === 1,
            handleTenantChooseProperties: context === AppContext.Tenant ? () => dispatch(setCurrentRentingPeriodId(undefined)) : undefined,
            openMobileNotificationSheet: () => dispatch(setModalOpen(AppModals.NotificationsList)),
            actions: context === AppContext.Tenant ? tenantActions : ownerActions,
            areQuickActionsAvailable: editableProperties != null && editableProperties.length > 0,
            roleName: isWhitelabel ? undefined : user?.roles.roleName,
            validUntil: isWhitelabel || user?.roles.roleName === RoleName.FREE ? undefined : user?.roles.to,
            drawerContent: context === AppContext.Owner ? <NotificationsDrawerContent /> : undefined,
            onColumnReorder: handleColumnReorder,
            preferredGridOrdering: user.columnOrderByTablePreference,
            profilePhoto: user.profilePictureUrl,
            onProfilePhotoChange: handlePhotoUpload,
        }
    }, [
        user,
        isWhitelabel,
        currencies,
        context,
        tenantActions,
        ownerActions,
        options,
        editableProperties,
        userNotifications,
        incomesSummaryStatus,
        soonToBeExpiredRentingPeriods,
        tenantRentingPeriods,
        dispatch,
        handleColumnReorder,
    ])

    if (isLoading || isUnitsLoading || context == null || (context === AppContext.Tenant && currentPropertyId == null)) {
        return <LoadingScreen />
    }

    return (
        <UserContextProvider userData={userDataConfig}>
            <DBLayout
                data={{ nav: getNavConfig() }}
                // getLevelColor={"primary"}
                // options={options}
            >
                <PWANotifications />
                {context === AppContext.Owner && <Breadcrumbs />}
                {!isCurrentRentingPeriodActive && (
                    <Alert
                        severity='warning'
                        sx={{ mb: 2 }}
                        action={<Button onClick={() => dispatch(setCurrentRentingPeriodId(undefined))}>{translate("change_contract")}</Button>}
                    >
                        <Typography>{translate("tenant_viewing_finished_contract")}</Typography>
                    </Alert>
                )}
                {children}
            </DBLayout>
        </UserContextProvider>
    )
}

export default DashboardLayout
