import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query"
import Api from "../api/Api"
import { DateTime } from "luxon"
import { ComplexQueryIDs, SimpleQueryIDs } from "../hooks/useQueryInvalidator"
import { TenantRequiredInfo } from "../sections/contracts/newContract/TenantRequiredData"
import { PropertyDataRequiredInfo } from "../sections/contracts/newContract/PropertyData"
import { AppContext, Currencies, CustomFile, Furniture, TVType } from "rentzz"
import { Partition } from "../sections/propertyDetails/marketing/addNewAd/details/Details"
import { PaymentSubtype, PaymentType, PropertyDataInfo, PropertyDetails, ProviderStatus } from "../redux/slices/AddProperty"
import { useSelector } from "../redux/store"
import { ContactInfoData } from "../sections/contracts/livePreview/tenant/TenantContactInfo"
import { ManagerType } from "../sections/propertyDetails/General/management/managers/AddNewManagerInvitationForm"
import { GridSortModel } from "@mui/x-data-grid-pro"
import { orderBy, sum } from "lodash"
import { useFeatureIsOn, useGrowthBook } from "@growthbook/growthbook-react"

export enum Banks {
    AlphaBank,
    BRCI,
    FeroviaraBank,
    IntesaSanpaolo,
    BCR,
    BCRHomeBank,
    Eximbank,
    RomanianBank,
    TransilvaniaBank,
    Leumi,
    BRD,
    CECBank,
    CreditAgricole,
    CreditEurope,
    GarantiBank,
    IdeaBank,
    LibraBank,
    VistaBank,
    OTPBank,
    PatriaBank,
    FirstBank,
    PorscheBank,
    ProCreditBank,
    Raiffeisen,
    AedificiumHomeBank,
    UniCredit,
    AliorBank,
    BLOMBankFrance,
    BNPParibas,
    Citibank,
    ING,
    TBIBank,
    Revolut,
}
export interface UserBankAccountResponseBase {
    count: number
}

export interface UserBankAccountResponseDto extends UserBankAccountResponseBase {
    items: UserBankAccountDto[]
}
export interface UserBankAccountResponse extends UserBankAccountResponseBase {
    items: UserBankAccount[]
}
export interface UserBankAccountBase {
    id: number
    holder: string
    bank: number
    iban: string
    currencyId: number
    properties: number[]
}

export interface UserBankAccountDto extends UserBankAccountBase {
    createdAt: string
    lastModifiedAt: string
}

export interface UserBankAccount extends UserBankAccountBase {
    createdAt: DateTime
    lastModifiedAt: DateTime
}

export interface UserInvoiceBase {
    id: number
    name: string
    amount: string
}

export interface UserInvoiceDto extends UserInvoiceBase {
    date: string
}
export interface UserInvoice extends UserInvoiceBase {
    date: DateTime
}
export enum UserFlags {
    ArchiveProperties = "archiveproperties",
    BackendPath = "backend-path",
    Email = "email",
    ExpenseProviders = "expense-providers",
    MeterProviders = "meter-providers",
    WhiteLabel = "is-white-label",
    Location = "location",
    Logo = "logo",
    Name = "name",
    PhoneNumber = "phone-number",
    PoliciesPageUrl = "policies-page-url",
    PrivacyPolicyUrl = "privacy-policy-url",
    Schedule = "schedule",
    ThemeError = "theme-error",
    ThemeInfo = "theme-info",
    ThemePrimary = "theme-primary",
    ThemeSecondary = "theme-secondary",
    ThemeSuccess = "theme-success",
    ThemeWarning = "theme-warning",
    TosUrl = "tos-url",
    UsersList = "users-list",
    LogoWithoutText = "logo-without-text",
    LogoWithText = "logo-with-text",
    LogoForNotification = "logo-for-notification",
    ShowInvoices = "show-invoices",
    OpenReplay = "open-replay",
    AcceptOnTenantBehalf = "accept-on-tenant-behalf",
    PromoCode = "promo-code",
    PropertyAd = "property-ad",
    ExpenseDaysWarning = "expense-days-warning",
    CanDeleteRentingPeriod = "delete-renting-periods",
    DeleteUser = "delete-user",
    InstallPrompt = "nstall-prompt",
    NotificationPrompt = "notification-prompt",
    VAPID_KEY = "vapid-key",
    SendLabelToTenant = "send-label-to-tenant",
    UsageGuide = "usage-guide",
    TenantGuides = "tenant-guides",
    DefaultAssignee = "default-assignee",
    TaskItems = "taskitems",
    PropertiesTableConfiguredProviders = "properties-table-configured-providers",
    SendAutomaticallyExpenseToProvider = "send-expense-automatically-to-tenant",
    SupportIframe = "support-iframe",
    TakeMeToInvoicesCustomColor = "take-me-to-invoices-custom-color",
    InvoicingConfigurations = "invoicing-configurations",
    DefaultInvoicingTemplateByLanguage = "default-invoicing-template-by-language",
    ExpenseHeaderOverride = "expense-header-override-general-expenses",
    MoreThanOneAssignedExpense = "more-than-one-assigned-expense",
    ShowReinvoicingFields = "show-reinvoicing-fileds",
    HideTenantRevisions = "hide-tenant-revisions",
    PercentExpensePayment = "percent-expense-payment",
    ShowPaymentNotifications = "ShowPaymentNotifications",
    ShowDashboardGroup = "show-dashboard-group",
    ShowManagerContext = "show-manager-context",
    ExpenseOnlinePayment = "expense-online-payment",
    CustomPaidBy = "custom-paid-by-me",
    AutoRemoveTenantAccessDays = "auto-remove-tenant-access-days",
    CanEditFinishedRentingPeriods = "edit-finished-renting-periods",
    ExpensesAIExtractor = "expenses-ai-extractor",
    SyncImoCRMAgency = "sync-imocrm",
    BankStatementsProcessing = "bank-statements-processing",
    DryRunInvoicing = "dry-run-invoicing",
}
export interface ActiveUserBase {
    id: string
    fullName: string
    eMail: string
    isLocked: boolean
    phoneNumber: string
    isSMSCodeActive: boolean
    accountType: AccountType
}

export interface ActiveUserDto extends ActiveUserBase {
    createdAt: string
    lastActive: string
}
export interface ActiveUser extends ActiveUserBase {
    createdAt: DateTime
    lastActive: DateTime
}

export interface ActiveUserResponseBase {
    count: number
}

export interface ActiveUserResponseDto extends ActiveUserResponseBase {
    items: ActiveUserDto[]
}
export interface ActiveUserResponse extends ActiveUserResponseBase {
    items: ActiveUser[]
}

export enum LabelType {
    Expenses,
}

export interface LabelDescriptionBase {
    id: number
    value: string
    color: string
    canEdit: boolean
}

export interface LabelDescriptionDto extends LabelDescriptionBase {
    createdAt: string
    lastModifiedAt: string
}

export interface LabelDescription extends LabelDescriptionBase {
    createdAt: DateTime
    lastModifiedAt: DateTime
}

export interface CustomProviderDto extends CustomProviderBase {
    createdAt: string
    lastModifiedAt: string
}

export interface CustomProvider extends CustomProviderBase {
    createdAt: DateTime
    lastModifiedAt: DateTime
}

export interface ProviderDetail {
    id: number
    propertyId: number
    status: ProviderStatus
}

export interface CustomProviderBase {
    id: number
    name: string
    canEdit: boolean
    hasDetails: boolean
}

export enum UserWithPromoCodeStatus {
    PENDING,
    APPROVED,
}

export interface UsersWithPromoCodeCommon {
    id: number
    fullName: string
    status: UserWithPromoCodeStatus
}

export interface UsersWithPromoCodeDto extends UsersWithPromoCodeCommon {
    promotionalCodeUsedAt: string
}

export interface UsersWithPromoCode extends UsersWithPromoCodeCommon {
    promotionalCodeUsedAt: DateTime
}

export const useNotificationsTemplatesQuery = () => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [SimpleQueryIDs.NotificationsTemplates],
        queryFn: Api.fetchNotificationsTemplates,
        staleTime: Infinity,
        enabled: context === AppContext.Owner,
    })
}

export const useNotificationTemplateDetailsQuery = (notificationId?: number) => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [ComplexQueryIDs.NotificationDetails, { notificationId }],
        queryFn: Api.getNotificationTemplateDetails,
        staleTime: Infinity,
        enabled: context === AppContext.Owner && !!notificationId,
    })
}

export const useUserBankAccountsQuery = (propertyId?: number) => {
    const { context, currentPropertyId } = useSelector((state) => state.appState)
    return useQuery({
        queryKey: [ComplexQueryIDs.BankAccounts, { propertyId: propertyId ?? currentPropertyId }],
        queryFn: Api.fetchUserBankAccounts,
        staleTime: Infinity,
        enabled: context === AppContext.Owner,
    })
}

export const useUserDataQueryFn = () => {
    const client = useQueryClient()
    const growthBook = useGrowthBook()
    const { context } = useSelector((state) => state.appState)
    const isWhiteLabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())

    return useQuery({
        staleTime: Infinity,
        queryKey: [SimpleQueryIDs.UserData],
        queryFn: async () => {
            const userData = await Api.fetchUserData()
            growthBook?.setAttributes({ id: userData?.id, isTrial: userData?.roles.isTrial ?? false })
            if (userData) {
                console.log("Logging in")
                // TODO: Add firebase subscription
            }
            return userData
        },
        retry: false,
        refetchOnWindowFocus: false,
        select: (
            data,
        ):
            | ((UserData | undefined) & {
                  canAddProperty: boolean
                  allCards: SummaryCardType[]
                  allCharts: DashboardChartType[]
                  isNonWhiteLabelOrWhiteLabelAdminUser: boolean
              })
            | null => {
            if (data == null) return null
            const numberOfOwnProperties = (client.getQueryData([SimpleQueryIDs.UserProperties, { context }]) as PropertySummary[])?.filter(
                (property) => property.createdByMe,
            )
            const canStillAddProperty = (numberOfOwnProperties ?? []).length <= (data?.features?.maxNumberOfProperties ?? 0)
            const cards = data.summaryCards.filter(
                (card) => card.entryType === (context === AppContext.Owner ? DashboardEntryType.Owner : DashboardEntryType.Tenant),
            )

            const charts = data.dashboardCharts.filter(
                (chart) => chart.entryType === (context === AppContext.Owner ? DashboardEntryType.Owner : DashboardEntryType.Tenant),
            )

            const orderedCards = orderBy(cards, ["order"], "asc")
            const orderedCharts = orderBy(charts, ["order"], "asc")
            return {
                ...data,
                canAddProperty: canStillAddProperty,
                summaryCards: orderedCards,
                allCards: data.summaryCards,
                dashboardCharts: orderedCharts,
                allCharts: data.dashboardCharts,
                isNonWhiteLabelOrWhiteLabelAdminUser: !isWhiteLabel || (isWhiteLabel && data.accountType === AccountType.WHITELABEL_ADMIN_USER),
            }
        },
    })
}

export const useUserNotificationsQuery = () => {
    const { context } = useSelector((state) => state.appState)
    const { data: userData } = useUserDataQueryFn()
    return useQuery({
        queryKey: [SimpleQueryIDs.UserNotifications],
        queryFn: Api.fetchUserNotifications,
        staleTime: Infinity,
        enabled: !!userData && context === AppContext.Owner,
    })
}

export const useUserLabelsQuery = () => {
    const { context } = useSelector((state) => state.appState)
    const { data: userData } = useUserDataQueryFn()
    return useQuery({
        queryKey: [SimpleQueryIDs.UserLabels],
        queryFn: Api.fetchUserLabels,
        staleTime: Infinity,
        enabled: !!userData && context === AppContext.Owner,
    })
}

export const useUserProvidersQuery = () => {
    const { context } = useSelector((state) => state.appState)
    const { data: userData } = useUserDataQueryFn()
    return useQuery({
        queryKey: [SimpleQueryIDs.UserProviders],
        queryFn: Api.fetchUserProviders,
        staleTime: Infinity,
        enabled: !!userData && context === AppContext.Owner,
    })
}

export const useProviderDetailsQuery = (providerId: number) => {
    const { context } = useSelector((state) => state.appState)
    return useQuery({
        queryKey: [ComplexQueryIDs.ProviderDetails, { providerId }],
        queryFn: Api.fetchProviderDetails,
        staleTime: Infinity,
        enabled: !!providerId && context === AppContext.Owner,
    })
}

export const useAvailableSummaryCardsQuery = () => {
    const { data: userData } = useUserDataQueryFn()
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [SimpleQueryIDs.AvailableSummaryCards, { context }],
        queryFn: Api.fetchAvailableSummaryCards,
        staleTime: Infinity,
        enabled: !!userData && context != null,
    })
}

export const useAvailableChartsQuery = () => {
    const { data: userData } = useUserDataQueryFn()
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [SimpleQueryIDs.AvailableCharts, { context }],
        queryFn: Api.fetchAvailableCharts,
        staleTime: Infinity,
        enabled: !!userData && context != null,
    })
}

export const useUserPropertiesQuery = (onlyActive: boolean = true) => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [SimpleQueryIDs.UserProperties, { context }],
        queryFn: Api.fetchUserProperties,
        staleTime: Infinity,
        enabled: context === AppContext.Owner,

        select: (data) => {
            if (onlyActive) return data.filter((p) => !p.isArchived)
            return data.map((property) => ({
                ...property,
                canDelete: [ManagerType.FULL, ManagerType.READ_WRITE].includes(property.ownershipType),
            }))
        },
    })
}

export const useTenantRentingPeriodsQuery = () => {
    const { context } = useSelector((state) => state.appState)
    const { data: isSignedIn } = useUserDataQueryFn()
    return useQuery({
        queryKey: [SimpleQueryIDs.TenantRentingPeriods, { context }],
        queryFn: async () => {
            const data = await Api.fetchUserRentingPeriods()
            const localStorageData = localStorage.getItem("currentRentingPeriodId")
            if (localStorageData) {
                const lastRememberedData = data.find((rp) => rp.rentingPeriodId.toString() == localStorageData)
                if (lastRememberedData == null) localStorage.removeItem("currentRentingPeriodId")
            }

            return data
        },
        staleTime: Infinity,
        enabled: context === AppContext.Tenant && isSignedIn != null,
    })
}

export const useUserContractTemplatesQuery = (isArchived: boolean) => {
    const { context } = useSelector((state) => state.appState)
    const { data: user } = useUserDataQueryFn()

    return useQuery({
        queryKey: [ComplexQueryIDs.ContractTemplates, { isArchived }],
        queryFn: Api.fetchUserContractTemplates,
        staleTime: Infinity,
        enabled: context === AppContext.Owner && user?.roles != null,
    })
}

export const useUserBillingQuery = () => {
    return useQuery({ staleTime: Infinity, queryKey: [SimpleQueryIDs.BillingDetails], queryFn: Api.fetchBillingDetails })
}

export const useUserTenantRequests = () => {
    const { context } = useSelector((state) => state.appState)
    const { data: user } = useUserDataQueryFn()
    return useQuery({
        queryKey: [SimpleQueryIDs.TenantRequests],
        queryFn: Api.fetchUserTenantRequests,
        staleTime: Infinity,
        enabled: context === AppContext.Tenant && user != null,
    })
}

export const useManagerRequests = () => {
    const { context } = useSelector((state) => state.appState)
    return useQuery({
        staleTime: Infinity,
        enabled: context === AppContext.Owner,
        queryFn: Api.fetchManagerRequests,
        queryKey: [SimpleQueryIDs.ManagerRequest],
    })
}

export const useUsersWithPromoCode = () => {
    return useQuery({ staleTime: Infinity, queryFn: Api.getUsersUsingPromoCode, queryKey: [SimpleQueryIDs.PromoCode] })
}

export const useActiveUsers = (page: number, pageSize: number, sortingColumns: GridSortModel) => {
    const usersList = useFeatureIsOn(UserFlags.UsersList.toString())
    const { data: userData } = useUserDataQueryFn()
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [ComplexQueryIDs.ActiveUsers, { page, pageSize, sortingColumns }],
        queryFn: Api.fetchActiveUsers,
        staleTime: Infinity,
        enabled: isWhitelabel && usersList && context === AppContext.Owner && userData?.accountType === AccountType.WHITELABEL_ADMIN_USER,
    })
}

export const useInfiniteActiveUsers = (sortingModel?: GridSortModel) => {
    const usersList = useFeatureIsOn(UserFlags.UsersList.toString())
    const { context } = useSelector((state) => state.appState)
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())
    const { data: userData } = useUserDataQueryFn()
    return useInfiniteQuery({
        initialPageParam: 0,
        queryKey: [ComplexQueryIDs.ActiveUsers, { sortingModel }],
        queryFn: Api.fetchInfiniteActiveUsers,
        enabled: isWhitelabel && usersList && context === AppContext.Owner && userData?.accountType === AccountType.WHITELABEL_ADMIN_USER,
        staleTime: Infinity,
        getNextPageParam: (_, pages) => {
            const allItems = sum(pages.flatMap((p) => p.items.length))
            if (allItems === pages[0].count) {
                return undefined
            }
            return allItems
        },
        select: (data) => {
            return {
                pages: [...data.pages],
                pageParams: data.pageParams,
            }
        },
    })
}

export const useUserInvoices = () => {
    return useQuery({ staleTime: Infinity, queryFn: Api.fetchUserInvoices, queryKey: [SimpleQueryIDs.Invoices] })
}

export const useUserC168AddressesQuery = () => {
    const { context } = useSelector((state) => state.appState)
    const { data: userData } = useUserDataQueryFn()

    return useQuery({
        queryKey: [SimpleQueryIDs.C168Addresses],
        queryFn: Api.fetchC186Addresses,
        staleTime: Infinity,
        enabled: !!userData && context === AppContext.Owner,
    })
}

export const useSingleActiveUserQuery = (activeUserId?: string) => {
    const { context } = useSelector((state) => state.appState)
    const queryClient = useQueryClient()
    const { data: userData } = useUserDataQueryFn()
    const isWhiteLabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())
    const usersList = useFeatureIsOn(UserFlags.UsersList.toString())

    return useQuery({
        queryFn: async () => {
            let toReturn: ActiveUser | undefined
            const activeUsersPages = queryClient.getQueriesData({
                queryKey: [ComplexQueryIDs.ActiveUsers],
                stale: false,
            })

            const arrayOfActiveUsers = (
                activeUsersPages?.flatMap((activeUserWithKey) => (activeUserWithKey[1] ? activeUserWithKey[1] : [])) as ActiveUserResponse[]
            )?.flatMap((r) => r?.items || []) as ActiveUser[]

            const activeUser = arrayOfActiveUsers?.find((activeUser) => activeUser?.id == activeUserId)

            if (activeUser) {
                toReturn = {
                    ...activeUser,
                }
            } else toReturn = activeUser ?? (await Api.getSingleActiveUser(activeUserId!)) ?? undefined

            return toReturn ?? null
        },
        queryKey: [ComplexQueryIDs.ActiveUser, { activeUserId }],
        staleTime: Infinity,
        enabled:
            !!activeUserId &&
            isWhiteLabel &&
            context === AppContext.Owner &&
            userData?.accountType === AccountType.WHITELABEL_ADMIN_USER &&
            usersList,
    })
}

export const useUserCardPaymentDetailsQuery = () => {
    const { context } = useSelector((state) => state.appState)
    const { data: userData } = useUserDataQueryFn()

    return useQuery({
        queryKey: [SimpleQueryIDs.CardPaymentDetails],
        queryFn: Api.fetchUserCardPaymentDetails,
        staleTime: Infinity,
        enabled: !!userData && context === AppContext.Tenant,
    })
}

export enum DashboardEntryType {
    Tenant,
    Owner,
}
export interface SummaryCardType {
    id: number
    title: string
    summaryCardId: number
    entryType: DashboardEntryType
    order: number
}

export interface DashboardChartType {
    id: number
    title: string
    dashboardChartId: number
    entryType: DashboardEntryType
    order: number
}

export interface UserNotifications {
    pendingReadingsToSend: number
    pendingExpensesToAccept: number
}

export interface UserDataCommon {
    id: string
    canTrial: boolean
    isSubscriptionExpired: boolean
    languageCode: string
    currency: {
        id: number
        code: Currencies
    }
    avatar: string
    summaryCards: SummaryCardType[]
    dashboardCharts: DashboardChartType[]
    dashboardTablesIds: number[]
    tenantDashboardTablesIds: number[]
    showTOS: boolean
    showPrivacyPolicy: boolean
    features?: {
        maxNumberOfProperties: number
        maxPropertySize: number
    }
    promoCode: string
    accountType: AccountType
    email: string
    lastName: string
    firstName: string
    isOblioConfigured: boolean
    enabledPushNotifications: number[]
    enabledEmailNotifications: number[]
    hasCardPaymentDetails: boolean
    imoCRMAgency: string
    columnOrderByTablePreference?: Record<string, string[]>
    profilePictureUrl?: string
}

export interface UserDataDto extends UserDataCommon {
    roles: UserRolesDto
}

export interface UserData extends UserDataCommon {
    roles: UserRoles
}

export interface ExtendedUserData extends UserData {
    isMaxNumberOfPropertiesExceeded: boolean
}

export interface UserRolesCommon {
    roleName: RoleName
    isTrial: boolean
}

export interface UserRolesDto extends UserRolesCommon {
    from: string
    to: string
}

export interface UserRoles extends UserRolesCommon {
    from?: DateTime
    to?: DateTime
}

export enum ContactInfoType {
    Owner = 0,
    Tenant = 1,
}
export enum SignatureType {
    Owner = 0,
    Tenant = 1,
}
export interface ContractSignature {
    signatureType: SignatureType
    signatureImage: string
    name: string
}

export enum ContactInfoPersonType {
    Individual = 0,
    Company = 1,
}

export interface ContractToBeSigned {
    litigation: string[]
    partiesRequirements: string[]
    ownerObligations: string[]
    tenantObligations: string[]
    tenantRequiredInfo: TenantRequiredInfo
    propertyData: PropertyDataInfo
    signatures: ContractSignature[]
    contactInfos: ContactInfoData[]
    hasGDPR: boolean
}

export interface TenantRequestCommon {
    id: number
    owner: string
    value: number
    currencyId: number
    securityDeposit?: number
    securityDepositCurrencyId?: number
    paymentPeriod: number[]
    bankName?: string
    bankAccount?: string
    meters: {
        name: string
        unitId: number
        currentValue: number
        reportingPeriodStartDay: number
        reportingPeriodEndDay: number
    }[]
    contractToBeSigned?: ContractToBeSigned
}

export interface TenantRequestDto extends TenantRequestCommon {
    invitedDate: string
    moveInDate: string
    moveOutDate: string
}

export interface TenantRequest extends TenantRequestCommon {
    invitedDate: DateTime
    moveInDate: DateTime
    moveOutDate: DateTime
}

export interface BillingDetailsCommon {
    price: number
    currencyId: number
    subscriptionName: string
    subscriptionPeriodInMonths: number
    address: string
    name: string
}

export interface ManagerRequestBase {
    id: number
    city: string
    county: string
    street: string
    streetNumber: string
    buildingNumber: string
    stair: string
    apartment: string
    floor: string
    inviter: string
    ownershipType: ManagerType
    name: string
}

export interface ManagerRequestDto extends ManagerRequestBase {
    invitedDate: string
}

export interface ManagerRequest extends ManagerRequestBase {
    invitedDate: DateTime
}

export interface BillingDetailsDto extends BillingDetailsCommon {
    expiry: string
}

export interface BillingDetails extends BillingDetailsCommon {
    expiry: DateTime
}

export enum RoleName {
    BRONZE = "Bronze",
    SILVER = "Silver",
    GOLD = "Gold",
    FREE = "Free",
}

interface UserContractTemplateCommon {
    id: string
    label: string
    canDelete: boolean
    numberOfActiveContracts: number
    ownerContactInfo: Omit<ContactInfoData, "type" | "contactInfoPersonType">
    tenantRequiredInfo: TenantRequiredInfo
    propertyDataRequiredInfo: PropertyDataRequiredInfo
    ownerObligations: string[]
    tenantObligations: string[]
    partiesRequirements: string[]
    litigation: string[]
    hasGDPR: boolean
    isArchived: boolean
}

export interface UserContractTemplateDto extends UserContractTemplateCommon {
    createdAt: string
}

export interface UserContractTemplate extends UserContractTemplateCommon {
    createdAt: DateTime
}

interface UserContractCommon {
    contractId: string
    contractTemplateId: string
    propertyData: PropertyDetails
}

export interface UserContractDto extends UserContractCommon {
    createdAt: string
}

export interface UserContract extends UserContractCommon {
    createdAt: DateTime
}

export enum NotificationVariableType {
    Unknown,
    UserConfigured,
    SystemConfigured,
}
export interface NotificationTemplateVariable {
    pattern: string
    value: string
    type: NotificationVariableType
}

export enum SystemConfiguredVariables {
    None,
    TenantsName,
    TodayDate,
    TenantsMail,
    OverdueExpensesAmountInRon,
    InvoicingCompanyCIF,
    InvoicingCompanyName,
    PropertyCounty,
    PropertyCity,
    PropertyStreet,
    PropertyStreetNumber,
    PropertyApartmentNumber,
    OverdueExpensesTableInRon,
}
export interface NotificationTemplateDetails {
    id: number
    content: string
    variables: NotificationTemplateVariable[]
    overdueDaysUntilSending: number
    name: string
    language: string
}
export interface NotificationTemplateBase {
    id: number
    name: string
    numberOfRentingPeriods: number
}

export interface NotificationTemplateDto extends NotificationTemplateBase {
    createdAt: string
}

export interface NotificationTemplate extends NotificationTemplateBase {
    createdAt: DateTime
}

export interface NotificationTemplateResponseBase {
    count: number
}

export interface NotificationTemplateResponseDto extends NotificationTemplateResponseBase {
    items: NotificationTemplateDto[]
}
export interface NotificationTemplateResponse extends NotificationTemplateResponseBase {
    items: NotificationTemplate[]
}

export interface PropertySummary {
    label: string
    id: number
    city: string | undefined
    area: string | undefined
    county: string | undefined
    rentPrice: number | undefined
    currencyId: number | undefined
    currentSize: number
    numberOfExpenses: number
    numberOfIncome: number
    numberOfDocuments: number
    numberOfContracts: number
    numberOfInsurances: number
    numberOfMeterValues: number
    numberOfObservations: number
    createdByMe: boolean
    ownershipType: ManagerType
    isArchived: boolean
    configuredMeterProviders: number
    configuredMeterExpenses: number
    unpaidExpensesStatus: UnpaidExpenseStatus[]
    totalMeters: number
    lastAcceptedRentPaymentDate?: string
    labelColor?: string
    labelName?: string
    labelId?: number
}

export interface UnpaidExpenseStatus {
    paymentType: PaymentType
    paymentSubtype: PaymentSubtype
    amount: number
}

export enum PropertyAutoAcceptType {
    None = 0,
    OnlyWithFiles = 1,
    All = 2,
}
export interface PropertyAdDetails {
    size: number | null
    partition?: Partition
    floor?: string
    furniture?: Furniture
    rooms?: number
    bathrooms?: string
    tv?: TVType
    hasHeater: boolean
    hasAC: boolean
    hasFridge: boolean
    hasDishwasher: boolean
    hasWashingMachine: boolean
    hasHood: boolean
    hasElevator: boolean
    hasDoubleGlazing: boolean
    hasInsulation: boolean
}

export interface TenantBankAccount {
    id: number
    holder: string
    bank: Banks
    iban: string
    currencyId: number
}
export interface TenantRentingPeriodBase {
    city: string
    county: string
    ownerName: string
    rentPrice: number
    currencyId: number
    propertyId: number
    rentingPeriodId: number
    remainingUploadSize: number
    street?: string
    showExpirationForm: boolean
    acceptPartialPayments: boolean
    bankAccounts: TenantBankAccount[]
}
export interface TenantRentingPeriodDto extends TenantRentingPeriodBase {
    from: string
    to: string
}
export interface TenantRentingPeriod extends TenantRentingPeriodBase {
    from: DateTime
    to: DateTime
}

export interface PropertyAdData extends PropertyAdDetails {
    title?: string
    county?: string
    city?: string
    address?: string
    photos: CustomFile[]
    rentPrice?: number
    currencyId?: number
    description?: string
    phone: string
    propertyId?: number
}

export enum AccountType {
    UNKNOWN = 0,
    WHITELABEL_ADMIN_USER = 1,
    WHITELABEL_USER = 2,
}
