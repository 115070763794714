import React, { useCallback, useMemo, useState } from "react"
import { GridActionsCellItem, GridColDef, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro"
import { AppContext, CustomDataGrid, Iconify, Label, useLocales } from "rentzz"
import { DateTime } from "luxon"
import { useDispatch, useSelector } from "../../../redux/store"
import {
    AccountType,
    UserFlags,
    useUserDataQueryFn,
    useUserLabelsQuery,
    useUserPropertiesQuery,
    useUserProvidersQuery,
} from "../../../queries/userData"
import { PropertyExpense, useExpensesQueryFn } from "../../../queries/expenses"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"
import { getAssigneeLabelColor } from "../utils"
import NoPropertyPage from "../../../guards/no-property/NoPropertyPage"
import CustomExpenseToolbar from "../Toolbar"
import NoPendingExpensesPage from "./NoPendingExpensesPage"
import ActionWithTooltip from "../../../utils/ActionWithTooltip"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import { useTheme } from "@mui/material/styles"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import StopCircleIcon from "@mui/icons-material/StopCircle"

interface Props {
    getAmount: (value: number, expenseId?: number) => string
}

const DesktopPendingExpensesList = ({ getAmount }: Props) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "date", sort: "desc" }])
    const { translate, currentLang } = useLocales()
    const { context, currentPropertyId } = useSelector((state) => state.appState)
    const { data: pendingExpenses, isFetching } = useExpensesQueryFn(paginationModel.page, paginationModel.pageSize, true, sortModel)
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: userData } = useUserDataQueryFn()
    const { data: labels } = useUserLabelsQuery()
    const { data: userProviders } = useUserProvidersQuery()
    const isAutoSendEnabled = useFeatureIsOn(UserFlags.SendAutomaticallyExpenseToProvider as string)
    const customPaidBy = useFeatureValue<string | null>(UserFlags.CustomPaidBy, null)

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            type: "actions",
            minWidth: 90,
            disableReorder: true,
            flex: 0.2,
            headerName: translate("expenses.actions"),
            getActions: (params: GridRowParams) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"primary"}
                            icon={<RemoveRedEyeIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.PendingExpenseStatus))
                                dispatch(setEditingItem({ id: params.row.id, canWrite: params.row.canWrite }))
                            }}
                            label={translate("details")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("details"),
                    key: "details",
                    hidden: !params.row.canWrite,
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            icon={<StopCircleIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                if (!params.row.canWrite) return
                                dispatch(setModalOpen(AppModals.DeactivateSendingExpenseToTenant))
                                dispatch(setEditingItem({ id: params.row.id }))
                            }}
                            label={translate("stop")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("stop_sending_expense", {
                        sendingDate: params.row.dateAutomaticallySentToTenant?.toLocaleString(DateTime.DATE_SHORT),
                    }),
                    key: "stop",
                    hidden: !isAutoSendEnabled || params.row.dateAutomaticallySentToTenant == null,
                }),
                ActionWithTooltip({
                    element: <Iconify icon={"mdi:account-lock"} color={theme.palette.error.main} />,
                    tooltipText: translate("only_read_no_write"),
                    key: "unavailable",
                    hidden: params.row.canWrite,
                }),
            ],
        }),
        [dispatch, translate],
    )

    const getLabelColor = useCallback(
        (value: number) => {
            return labels?.find((label) => label.id === value)?.color
        },
        [labels],
    )

    const expenseColumns = useMemo(() => {
        const columns: GridColDef<PropertyExpense>[] = [
            {
                field: "propertyId",
                type: "string",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                headerName: translate("property"),
                valueFormatter: (value) => {
                    return userProperties?.find((p) => p.id === value)?.label
                },
            },
            {
                field: "name",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("expenses.name"),
            },
            {
                field: "value",
                type: "number",
                flex: 0.5,
                minWidth: 130,
                headerAlign: "center",
                align: "center",
                headerName: translate("expenses.value"),
                valueFormatter: (value, row) => {
                    return getAmount(value, row.currencyId as number)
                },
            },
            {
                field: "assignee",
                type: "string",
                minWidth: 120,
                headerAlign: "center",
                align: "center",
                headerName: translate("expenses.assignee"),
                renderCell: (v) => {
                    return (
                        <Label color={getAssigneeLabelColor(v.value)} style={{ textTransform: "none" }}>
                            {translate(`expenses.assignee-${v.value as number}${customPaidBy ? "-" + customPaidBy : ""}`)}
                        </Label>
                    )
                },
            },
            {
                field: "associatedProviderId",
                flex: 0.5,
                minWidth: 120,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("provider"),
                renderCell: (v) => {
                    const provider = userProviders?.find((p) => p.id === v.value)
                    return provider?.name
                },
            },
            {
                field: "date",
                type: "date",
                minWidth: 100,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("expenses.date"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "dueDate",
                type: "date",
                minWidth: 150,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("due_date"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "dateAutomaticallySentToTenant",
                type: "date",
                minWidth: 120,
                flex: 0.3,
                headerAlign: "center",
                align: "center",
                sortable: false,
                headerName: translate("automatic_date"),
                filterable: false,
                valueFormatter: (value) => ((value as DateTime)?.isValid ? (value as DateTime)?.toLocaleString(DateTime.DATE_SHORT) : ""),
            },
        ]

        if (currentPropertyId && context === AppContext.Owner) {
            return columns.filter((c) => c.field !== "propertyId")
        }
        if (!isAutoSendEnabled) return columns.filter((c) => c.field !== "dateAutomaticallySentToTenant")

        return columns
    }, [
        pendingExpenses,
        currentPropertyId,
        currentLang,
        userProperties,
        userData,
        getLabelColor,
        context,
        translate,
        labels,
        getAmount,
        userProviders,
        isAutoSendEnabled,
    ])

    if (userProperties?.length === 0 && userData?.accountType === AccountType.WHITELABEL_USER && context === AppContext.Owner) {
        return <NoPropertyPage />
    }

    return (
        <>
            <CustomExpenseToolbar canExport={false} numberOfItems={pendingExpenses?.count} />
            <CustomDataGrid
                gridId={"DesktopPendingExpensesList"}
                paginationMode={"server"}
                isLoading={isFetching}
                rowsLoadingMode={"client"}
                columns={expenseColumns}
                rows={pendingExpenses?.items ?? []}
                components={{
                    noRowsOverlay: () => <NoPendingExpensesPage />,
                }}
                idKey={"id"}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                sortingMode='server'
                totalCount={pendingExpenses?.count}
                actionsColumn={[actionsColumn]}
                onPaginationModelChange={setPaginationModel}
            />
        </>
    )
}

export default DesktopPendingExpensesList
