import { allLangs, AppContext, CustomDataGrid, ExpenseAssignee, Iconify, Label, LightTooltip, useLocales } from "rentzz"
import React, { useCallback, useMemo, useState } from "react"
import { RecurringExpense, useRecurringExpensesQuery } from "../../../queries/expenses"
import { GridActionsCellItem, GridColDef, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro"
import { DateTime } from "luxon"
import { UserFlags, useUserLabelsQuery, useUserPropertiesQuery, useUserProvidersQuery } from "../../../queries/userData"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { getAssigneeLabelColor, getRecurringTypeLabelColor, getRecurringTypeLabelText } from "../utils"
import { DESKTOP_ICON_SIZE, getReverseTextColor } from "../../../utils/helpers"
import { Box, Divider, Typography } from "@mui/material"
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff"
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive"
import CancelIcon from "@mui/icons-material/Cancel"
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked"
import InfoIcon from "@mui/icons-material/Info"
import DeleteIcon from "@mui/icons-material/Delete"
import NoRecurringExpensesPage from "./NoRecurringExpensesPage"
import ActionWithTooltip from "../../../utils/ActionWithTooltip"
import { useTheme } from "@mui/material/styles"
import CustomExpenseToolbar from "../Toolbar"
import ReceiptIcon from "@mui/icons-material/Receipt"
import { useCurrencyQueryFn } from "../../../queries/currency"
import { useFeatureValue } from "@growthbook/growthbook-react"

export interface RecurringExpenseTableProps {
    getAmount: (value: number, expenseId?: number) => string
}

const RecurringExpensesListDesktop = ({ getAmount }: RecurringExpenseTableProps) => {
    const { translate, currentLang } = useLocales()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { data: currencies } = useCurrencyQueryFn()
    const { context, currentPropertyId, currentRentingPeriodId } = useSelector((state) => state.appState)
    const customPaidBy = useFeatureValue<string | null>(UserFlags.CustomPaidBy, null)
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })
    const { data: labels } = useUserLabelsQuery()
    const { data: providers } = useUserProvidersQuery()
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "startDate", sort: "desc" }])
    const { data: recurringExpenses, isFetching } = useRecurringExpensesQuery(paginationModel.page, paginationModel.pageSize, sortModel)
    const { data: userProperties } = useUserPropertiesQuery()
    const getLabelColor = useCallback(
        (value: number) => {
            return labels?.find((label) => label.id === value)?.color
        },
        [labels],
    )

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            type: "actions",
            disableReorder: true,
            minWidth: 190,
            flex: 0.5,
            headerName: translate("expenses.actions"),
            getActions: (params: GridRowParams) => [
                // ActionWithTooltip({
                //     element: (
                //         <GridActionsCellItem
                //             key='edit'
                //             color='primary'
                //             icon={<ModeEditIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                //             onClick={(event: React.MouseEvent<HTMLElement>) => {
                //                 event.stopPropagation()
                //                 dispatch(setEditingItem({ id: params.row.id }))
                //                 dispatch(setModalOpen(AppModals.EditRecurringExpense))
                //             }}
                //             label={translate("grid_actions.edit")}
                //             sx={{ p: 1 }}
                //         />
                //     ),
                //     tooltipText: translate("edit"),
                //     key: "edit",
                //     hidden: !params.row.canWrite,
                // }),
                // ActionWithTooltip({
                //     element: (
                //         <GridActionsCellItem
                //             color={"info"}
                //             icon={<CloudDownloadIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                //             onClick={async (event: React.MouseEvent<HTMLElement>) => {
                //                 event.stopPropagation()
                //                 dispatch(setEditingItem({ id: params.id, type: PropertyFileType.RecurringExpense, name: params.row.name }))
                //                 dispatch(setModalOpen(AppModals.EntityFileManager))
                //             }}
                //             label={translate("see_files")}
                //             sx={{ p: 1 }}
                //         />
                //     ),
                //     tooltipText: translate("see_files"),
                //     key: "download",
                // }),
                // ActionWithTooltip({
                //     element: (
                //         <GridActionsCellItem
                //             color={params.row.isActive ? "error" : "success"}
                //             icon={
                //                 params.row.isActive ? (
                //                     <StopCircleIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                //                 ) : (
                //                     <PlayCircleIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                //                 )
                //             }
                //             onClick={async (event: React.MouseEvent<HTMLElement>) => {
                //                 event.stopPropagation()
                //                 console.log("stop")
                //             }}
                //             label={translate(params.row.isActive ? "recurring_expenses.stop_recurrence" : "recurring_expenses.activate_recurrence")}
                //             sx={{ p: 1 }}
                //         />
                //     ),
                //     tooltipText: translate(params.row.isActive ? "recurring_expenses.stop_recurrence" : "recurring_expenses.activate_recurrence"),
                //     key: "stop_recurrence",
                // }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            key='delete'
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.DeleteRecurringExpense))
                                dispatch(setEditingItem({ id: params.row.id }))
                            }}
                            label={translate("grid_actions.delete")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("delete"),
                    key: "delete",
                    hidden: !params.row.canDelete,
                }),
                ActionWithTooltip({
                    element: <Iconify icon={"mdi:account-lock"} color={theme.palette.error.main} />,
                    tooltipText: translate("only_read_no_write"),
                    key: "unavailable",
                    hidden: params.row.canDelete,
                }),
            ],
        }),
        [translate],
    )

    const expenseColumns = useMemo(() => {
        const columns: GridColDef<RecurringExpense>[] = [
            {
                field: "recurringType",
                type: "actions",
                minWidth: 160,
                flex: 0.5,
                headerClassName: "hideRightSeparator",
                headerName: "",
                renderCell: (v) => {
                    const item: RecurringExpense | null = v.api.getRow(v.id!)
                    return (
                        <Box gap={2} display='flex' alignItems='center'>
                            <LightTooltip
                                title={
                                    <Box display='flex' flexDirection='column' justifyContent='center'>
                                        <Typography variant='caption'>{translate("recurring_expenses.createdAt")}</Typography>
                                        <Typography variant='caption'>{(item?.createdAt as DateTime).toLocaleString(DateTime.DATE_SHORT)}</Typography>
                                        <Divider sx={{ marginY: 1 }} />
                                        <Typography variant='caption'>{translate("recurring_expenses.modifiedAt")}</Typography>
                                        <Typography variant='caption'>
                                            {(item?.modifiedAt as DateTime).toLocaleString(DateTime.DATE_SHORT)}
                                        </Typography>
                                    </Box>
                                }
                                arrow
                            >
                                <InfoIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} color={"info"} />
                            </LightTooltip>
                            <LightTooltip title={translate(`recurring-isActive-${item?.isActive}`)} arrow>
                                {item?.isActive === false ? (
                                    <CancelIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} color={"error"} />
                                ) : (
                                    <RadioButtonCheckedIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} color={"success"} />
                                )}
                            </LightTooltip>
                            <LightTooltip title={translate(`recurring-type-${v.value}`)} arrow>
                                <Label
                                    color={getRecurringTypeLabelColor(v.value)}
                                    style={{
                                        textTransform: "none",
                                    }}
                                >
                                    {translate(getRecurringTypeLabelText(v.value, currentLang.value, false))}
                                </Label>
                            </LightTooltip>
                            {item?.assignee === ExpenseAssignee.TENANT && (
                                <LightTooltip title={translate(`recurring-should-notify-${item.shouldNotifyOtherParty}`)} arrow>
                                    {item.shouldNotifyOtherParty ? (
                                        <NotificationsActiveIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} color={"primary"} />
                                    ) : (
                                        <NotificationsOffIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} color={"warning"} />
                                    )}
                                </LightTooltip>
                            )}
                            {item?.invoiceCompany && (
                                <LightTooltip
                                    title={
                                        <Box display='flex' flexDirection='column' justifyContent='center'>
                                            <Typography variant='caption'>{translate("invoicing_data")}</Typography>
                                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                                            <Typography variant='caption'>{translate("invoicing_company")}</Typography>
                                            <Typography variant='caption'>{item.invoiceCompany}</Typography>
                                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                                            <Typography variant='caption'>{translate("invoicing_client")}</Typography>
                                            <Typography variant='caption'>{item.invoiceClient}</Typography>
                                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                                            <Typography variant='caption'>{translate("invoicing_series")}</Typography>
                                            <Typography variant='caption'>{item.invoicingSeries}</Typography>
                                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                                            <Typography variant='caption'>{translate("invoicing_language")}</Typography>
                                            <Typography variant='caption'>
                                                {allLangs.find((lang) => lang.value === item.invoiceLanguage)?.label}
                                            </Typography>
                                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                                            <Typography variant='caption'>{translate("renting_period_invoicing_value")}</Typography>
                                            <Typography variant='caption'>{item.value}</Typography>
                                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                                            <Typography variant='caption'>{translate("invoicing_currency")}</Typography>
                                            <Typography variant='caption'>
                                                {currencies?.find((c) => c.id === item.invoiceCurrencyId)?.code}
                                            </Typography>
                                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                                            <Typography variant='caption'>{translate("TVA")}</Typography>
                                            <Typography variant='caption'>{item.invoiceTVA}</Typography>
                                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                                            <Typography variant='caption'>{translate("invoicing_product_name")}</Typography>
                                            <Typography variant='caption'>{item.invoiceProductName}</Typography>
                                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                                            <Typography variant='caption'>{translate("invoicing_product_description")}</Typography>
                                            <Typography variant='caption'>{item.invoiceProductDescription}</Typography>
                                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                                            <Typography variant='caption'>{translate("invoicing_period")}</Typography>
                                            <Typography variant='caption'>
                                                {item.invoicingPeriodStart} - {item.invoicingPeriodEnd}
                                            </Typography>
                                        </Box>
                                    }
                                    arrow
                                >
                                    <ReceiptIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} color={"info"} />
                                </LightTooltip>
                            )}
                        </Box>
                    )
                },
            },

            {
                field: "propertyId",
                type: "string",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                sortable: false,
                headerName: translate("property"),
                valueFormatter: (value) => {
                    return userProperties?.find((p) => p.id === value)?.label
                },
            },
            {
                field: "startDate",
                type: "date",
                minWidth: 140,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("recurring_expenses.startDate"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "expiresAt",
                type: "date",
                minWidth: 140,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("expirationDate"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime)?.toLocaleString(DateTime.DATE_SHORT) ?? "-",
            },
            {
                field: "name",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("name"),
            },
            {
                field: "value",
                type: "number",
                flex: 0.5,
                minWidth: 130,
                headerAlign: "center",
                align: "center",
                headerName: translate("expenses.value"),
                valueFormatter: (value, row) => {
                    return getAmount(value, row.currencyId as number)
                },
            },
            {
                field: "assignee",
                type: "string",
                minWidth: 120,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("expenses.assignee"),
                renderCell: (v) => {
                    return (
                        <Label color={getAssigneeLabelColor(v.value)} style={{ textTransform: "none" }}>
                            {translate(`expenses.assignee-${v.value as number}${customPaidBy ? "-" + customPaidBy : ""}`)}
                        </Label>
                    )
                },
            },
            {
                field: "associatedProviderId",
                flex: 0.5,
                minWidth: 100,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("provider"),
                renderCell: (v) => {
                    return providers?.find((p) => p.id === v.value)?.name
                },
            },
            {
                field: "labelId",
                type: "actions",
                flex: 0.2,
                minWidth: 130,
                headerClassName: "hideRightSeparator",
                headerName: translate("labelDescription"),
                renderCell: (v) => {
                    if (v.value == null) return ""
                    return (
                        <Label
                            style={{
                                textTransform: "none",
                                backgroundColor: `#${getLabelColor(v.value)}`,
                                color: getReverseTextColor(`#${getLabelColor(v.value)}`),
                            }}
                        >
                            {labels?.find((label) => label.id === v.value)?.value}
                        </Label>
                    )
                },
            },
        ]
        if ((currentPropertyId && context === AppContext.Owner) || currentRentingPeriodId) {
            return columns.filter((c) => c.field !== "propertyId")
        }

        return columns
    }, [recurringExpenses, userProperties, translate, labels, getAmount, getRecurringTypeLabelText, getRecurringTypeLabelColor, providers])

    return (
        <>
            <CustomExpenseToolbar onAddClick={() => dispatch(setModalOpen(AppModals.AddRecurringExpense))} canExport={false} />
            <CustomDataGrid
                gridId={"RecurringExpensesListDesktop"}
                paginationMode={"server"}
                isLoading={isFetching}
                rowsLoadingMode={"client"}
                columns={expenseColumns}
                rows={recurringExpenses?.items ?? []}
                idKey={"id"}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                sortingMode='server'
                totalCount={recurringExpenses?.count}
                actionsColumn={[actionsColumn]}
                components={{
                    noRowsOverlay: () => <NoRecurringExpensesPage />,
                }}
                onPaginationModelChange={setPaginationModel}
            />
        </>
    )
}

export default RecurringExpensesListDesktop
