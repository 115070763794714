import {
    CustomFile,
    CustomPopover,
    ExpenseAssignee,
    FormProvider,
    IFileManager,
    LoadingIcon,
    RHFDatePicker,
    RHFSelect,
    RHFTextField,
    RHFUnitInput,
    RHFUpload,
    useIsMobile,
    useLocales,
    usePopover,
} from "rentzz"
import { Alert, Box, Button, MenuItem } from "@mui/material"
import React, { MouseEvent, useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { useCurrencyQueryFn } from "../../../queries/currency"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { ExpensesSchema } from "../../../validations/expenses"
import { LoadingButton } from "@mui/lab"
import { useSinglePendingExpenseQueryFn } from "../../../queries/expenses"
import { downloadFile, processDownloadData } from "../../../utils/actions"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { deleteEntityFileMutation } from "../../../mutations/entityFile"
import { PaymentSubtype, PaymentType } from "../../../redux/slices/AddProperty"
import RHFProviderInput from "../../../components/RHFProviderInput"
import RHFLabelInput from "../../../components/RHFLabelInput"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../queries/userData"
import Api from "../../../api/Api"
import { updateExpenseMutation } from "../../../mutations/expenses"
import { DateTime } from "luxon"
import * as jsonpatch from "fast-json-patch"
import { debounce } from "lodash"
import ReinvoicingForm from "./ReinvoicingForm"
import RHFPaymentTypeInput from "../../../components/RHFPaymentTypeInput"

export interface AddPendingExpenseBase {
    id?: number
    name: string
    assignee: ExpenseAssignee
    value: number
    date: string
    dueDate: string
    files: IFileManager[] | CustomFile[]
    currencyId: number
    propertyId?: number
    labelId?: number
    providerId?: number
    shouldNotifyOtherParty?: boolean
}

export interface AddPendingExpenseFormData extends AddPendingExpenseBase {
    paymentType: string | PaymentType
    paymentSubtype?: PaymentSubtype
}

interface Props {
    onClose: () => void
    onSave: (data: AddPendingExpenseFormData) => Promise<void>
    isLoading: boolean
}
export default function AddPendingExpenseForm({ onClose, onSave, isLoading }: Props) {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const popover = usePopover()
    const { data: currencies } = useCurrencyQueryFn()
    const { currentPropertyId, editingItem } = useSelector((state) => state.appState)
    const { editableProperties } = usePermissions(PermissionType.PendingExpenses)
    const { data: currentPendingExpense } = useSinglePendingExpenseQueryFn(editingItem?.id)
    const { mutateAsync: deleteFile, isPending: isDeleteLoading } = deleteEntityFileMutation()
    const showReinvoicing = useFeatureIsOn(UserFlags.ShowReinvoicingFields.toString())
    const { mutate: updateExpense, isPending: isExpenseUpdating } = updateExpenseMutation()
    const [lockButtons, setLockButtons] = useState(false)
    const customPaidBy = useFeatureValue<string | null>(UserFlags.CustomPaidBy, null)

    const methods = useForm<AddPendingExpenseFormData>({
        resolver: yupResolver(ExpensesSchema()),
        mode: "onBlur",
    })

    const { reset, handleSubmit, watch, getValues, setValue } = methods

    const debouncedUpdate = useCallback(
        debounce((data: { propertyId?: number; expenseId: number; operations: jsonpatch.Operation[] }) => {
            setLockButtons(false)
            updateExpense(data)
        }, 1000),
        [updateExpense],
    )

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "files" || currentPendingExpense == null) return
            const operations = jsonpatch.compare(
                {
                    ...currentPendingExpense,
                    date: currentPendingExpense?.date.toISODate(),
                    dueDate: currentPendingExpense?.dueDate.toISODate(),
                    files: [],
                },
                {
                    ...v,
                    assignee: Number(v.assignee),
                    dueDate: DateTime.fromISO(v.dueDate!).toISODate(),
                    date: DateTime.fromISO(v.date!).toISODate(),
                    files: [],
                    paymentType: v.paymentType?.toString().startsWith(`${PaymentType.Expense}_`) ? PaymentType.Expense : v.paymentType,
                    paymentSubtype: v.paymentType?.toString().startsWith(`${PaymentType.Expense}_`)
                        ? (Number(v.paymentType.toString().split("_")[1]) as PaymentSubtype)
                        : PaymentSubtype.None,
                },
            )
            if (operations.length > 0) {
                setLockButtons(true)
                debouncedUpdate({ propertyId: v.propertyId, expenseId: v.id!, operations })
            }
        })

        return () => subscription.unsubscribe()
    }, [watch, currentPendingExpense, updateExpense])

    useEffect(() => {
        if (currentPendingExpense) {
            reset({
                ...currentPendingExpense,
                date: currentPendingExpense.date.toISO() ?? undefined,
                dueDate: currentPendingExpense.dueDate.toISO() ?? undefined,
                paymentType:
                    currentPendingExpense.paymentType === PaymentType.Expense
                        ? `${PaymentType.Expense}_${currentPendingExpense.paymentSubtype}`
                        : currentPendingExpense.paymentType,
            })
        }
    }, [currentPendingExpense, reset, updateExpense])

    const handleDownload = useCallback(
        async (file: any) => {
            if ("path" in file) {
                const filesWithPath = getValues("files").filter((f) => "path" in f && f.path) as CustomFile[]
                const selectedFile = filesWithPath.find((f) => f.path === file.path)

                if (selectedFile) {
                    if (selectedFile.path?.split(".").pop()?.toLowerCase() === "pdf") {
                        const url = selectedFile.preview
                        if (url) {
                            dispatch(
                                setEditingItem({
                                    ...editingItem,
                                    url: url,
                                    previousModal: AppModals.PendingExpenseStatus,
                                }),
                            )
                            dispatch(setModalOpen(AppModals.PdfViewer))
                        }
                    } else {
                        processDownloadData(selectedFile, selectedFile.name, selectedFile.path?.split(".").pop(), true)
                    }
                }
            } else {
                const filesWithId = getValues("files").filter((f) => "id" in f && f.id) as IFileManager[]
                const fileToDownload = filesWithId?.find((f) => f.id === file.id)
                if (fileToDownload?.extension?.toLowerCase() === "pdf") {
                    const fileUrl = await Api.downloadMultiFile([fileToDownload.id])
                    const url = window.URL.createObjectURL(new Blob([fileUrl]))
                    if (url) {
                        dispatch(
                            setEditingItem({
                                ...editingItem,
                                url: url,
                                previousModal: AppModals.PendingExpenseStatus,
                            }),
                        )
                        dispatch(setModalOpen(AppModals.PdfViewer))
                    }
                } else {
                    return downloadFile({
                        downloadId: file?.id,
                        downloadName: fileToDownload?.name ?? "unknown",
                        extension: fileToDownload?.extension,
                    })
                }
            }
        },
        [getValues],
    )

    const handleDelete = useCallback(
        async (file: IFileManager | CustomFile) => {
            const files = getValues("files")

            if ("path" in file) {
                setValue(
                    "files",
                    (files as CustomFile[]).filter((f: CustomFile) => f.path !== file.path),
                    { shouldValidate: true },
                )
            } else if ("id" in file) {
                setValue(
                    "files",
                    (files as IFileManager[]).filter((f: IFileManager) => f.id !== (file as IFileManager).id),
                    { shouldValidate: true },
                )
            }
        },
        [getValues],
    )

    const onSubmit = useCallback(
        async (data: AddPendingExpenseFormData) => {
            const newFiles = data.files.filter((f) => "path" in f) as CustomFile[]
            const previousFiles = currentPendingExpense?.files
            const deletedFiles = previousFiles?.filter((f) => !data.files.some((file) => "id" in file && file.id === f.id)) ?? []
            for (const deletedFile of deletedFiles) {
                await deleteFile({ fileId: deletedFile.id })
            }

            await onSave({ ...data, files: newFiles })
            onClose()
        },
        [onSave, onClose, reset, getValues, deleteFile, currentPendingExpense],
    )
    if (currentPendingExpense == null) return <LoadingIcon />

    return (
        <Box>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box display='flex' flexDirection='column' gap={2} marginY={2}>
                    <Alert severity={"info"}>{translate("add_pending_expense_info")}</Alert>
                    <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                        <RHFTextField name='name' label={translate("name")} size='small' required />

                        <RHFSelect name={"assignee"} size='small' variant={"outlined"} label={translate("assignee")} required>
                            <MenuItem value={0} style={{ textTransform: "none" }}>
                                {translate(`expenses.assignee-0${customPaidBy ? "-" + customPaidBy : ""}`)}
                            </MenuItem>

                            <MenuItem value={1} style={{ textTransform: "none" }}>
                                {translate("expenses.assignee-1")}
                            </MenuItem>
                        </RHFSelect>
                    </Box>
                    <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                        <RHFDatePicker name='date' label={translate("date")} required disableFuture />
                        <RHFDatePicker name='dueDate' label={translate("due_date")} required />
                    </Box>
                    <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                        <RHFUnitInput
                            data={currencies ?? []}
                            unitName={"currencyId"}
                            unitType={"currency"}
                            name='value'
                            type='number'
                            size='small'
                            label={translate("amount")}
                            required
                        />
                        <RHFPaymentTypeInput name='paymentType' />
                    </Box>
                    <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                        <RHFProviderInput name={"associatedProviderId"} />
                        <RHFLabelInput name={"labelId"} />
                    </Box>
                    <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                        <RHFSelect name={"propertyId"} size='small' disabled label={translate("property")} required={currentPropertyId == null}>
                            {editableProperties?.map((property) => (
                                <MenuItem key={property.id} value={property.id} style={{ textTransform: "none" }}>
                                    {property.label}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                    </Box>
                </Box>
                <RHFUpload
                    name={"files"}
                    icon={"mdi:file-document-plus-outline"}
                    multiple
                    thumbnail={false}
                    handleDelete={handleDelete}
                    onDownload={handleDownload}
                />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button color={"primary"} onClick={onClose} disabled={isLoading || isDeleteLoading || isExpenseUpdating || lockButtons}>
                        {translate("cancel")}
                    </Button>
                    <LoadingButton
                        variant='contained'
                        color={"error"}
                        onClick={() => {
                            dispatch(setEditingItem({ id: currentPendingExpense?.id }))
                            dispatch(setModalOpen(AppModals.DeletePendingExpense))
                        }}
                        disabled={isLoading || isDeleteLoading || isExpenseUpdating || lockButtons}
                        loading={isDeleteLoading || isLoading || isExpenseUpdating}
                    >
                        {translate("delete")}
                    </LoadingButton>
                    <LoadingButton
                        type='submit'
                        variant='contained'
                        color={"primary"}
                        disabled={isLoading || isDeleteLoading || isExpenseUpdating || lockButtons}
                        loading={isLoading || isExpenseUpdating}
                    >
                        {translate("submit")}
                    </LoadingButton>
                </Box>
            </FormProvider>
            <Box style={{ marginTop: isMobile ? 16 : -36 }}>
                {showReinvoicing && (
                    <LoadingButton
                        variant='contained'
                        color={"primary"}
                        fullWidth={isMobile}
                        onClick={(event: MouseEvent<HTMLElement>) => popover.onOpen(event)}
                        disabled={isLoading || !currentPendingExpense.canAddInvoice || isExpenseUpdating || lockButtons}
                    >
                        {translate("create_invoice_expense")}
                    </LoadingButton>
                )}

                <CustomPopover
                    open={popover.open}
                    onClose={popover.onClose}
                    sx={{
                        p: 2,
                        "& .MuiMenuItem-root": { px: 1, typography: "body2", borderRadius: 0.75 },
                    }}
                    anchorEl={popover.anchorEl}
                    slotProps={{
                        arrow: {
                            placement: "bottom-center",
                        },
                    }}
                >
                    <ReinvoicingForm onClose={popover.onClose} />
                </CustomPopover>
            </Box>
        </Box>
    )
}
