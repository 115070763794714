import { IconButton, MenuItem, Stack } from "@mui/material"
import { CustomPopover, Iconify, LightTooltip, useLocales, usePopover } from "rentzz"
import { useTheme } from "@mui/material/styles"
import React, { useCallback, useMemo } from "react"
import { useAllSectionsQueryFn } from "../../../../queries/kanban"
import { updateTaskMutation } from "../../../../mutations/kanban"
import * as jsonpatch from "fast-json-patch"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { LoadingButton } from "@mui/lab"
import { useUserDataQueryFn } from "../../../../queries/userData"

interface Props {
    sectionId?: string
    taskId: string
    onClose: () => void
}

export default function TaskDetailsHeader({ sectionId, taskId, onClose }: Props) {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const theme = useTheme()
    const { editingItem } = useSelector((state) => state.appState)
    const popover = usePopover()
    const { data: allSections } = useAllSectionsQueryFn()
    const { mutateAsync: editTaskSection, isPending: isEditTaskSectionLoading } = updateTaskMutation()
    const { data: user } = useUserDataQueryFn()

    const currentSection = useMemo(() => allSections?.find((section) => section.taskSectionId === sectionId), [sectionId, allSections])

    const closePopover = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation()
            event.preventDefault()
            popover.onClose()
        },
        [popover],
    )

    const handleChangeTaskSection = useCallback(
        async (newSectionId: string) => {
            const operations = jsonpatch.compare({ sectionId: sectionId }, { sectionId: newSectionId })
            if (sectionId == null) return
            await editTaskSection({
                sectionId,
                taskId,
                operations,
                newIndex: -1,
            })
            popover.onClose()
            dispatch(setEditingItem({ ...editingItem, sectionId: newSectionId }))
        },
        [sectionId, taskId, editTaskSection, popover, editingItem],
    )

    const onTaskDelete = useCallback(async () => {
        dispatch(setModalOpen(AppModals.DeleteTask))
    }, [dispatch, taskId, sectionId, onClose])

    return (
        <>
            <Stack py={1} pr={1} direction='row' alignItems='center' gap={1}>
                <IconButton onClick={onClose} size='small'>
                    <Iconify icon='mdi:close' />
                </IconButton>
                <LoadingButton
                    size='small'
                    variant='text'
                    endIcon={<Iconify icon='mdi:chevron-down' width={16} sx={{ ml: -0.5 }} />}
                    sx={{ backgroundColor: theme.palette.grey.A200 }}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => popover.onOpen(event)}
                    loading={isEditTaskSectionLoading}
                    disabled={isEditTaskSectionLoading || !currentSection?.canEdit || user?.roles == null}
                >
                    {currentSection?.name}
                </LoadingButton>
                <CustomPopover open={popover.open} anchorEl={popover.anchorEl} onClose={closePopover} sx={{ width: 140 }}>
                    {allSections?.map((section) => (
                        <MenuItem
                            key={section.taskSectionId}
                            onClick={async () => {
                                await handleChangeTaskSection(section.taskSectionId)
                            }}
                        >
                            {section.name}
                        </MenuItem>
                    ))}
                </CustomPopover>

                {currentSection?.canEdit && (
                    <Stack direction='row' spacing={1} justifyContent='flex-end' flexGrow={1}>
                        <LightTooltip arrow title={translate("delete_task")}>
                            <IconButton onClick={onTaskDelete} size='small' color={"error"}>
                                <Iconify icon='mdi:trash' />
                            </IconButton>
                        </LightTooltip>
                    </Stack>
                )}
            </Stack>
        </>
    )
}
