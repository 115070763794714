import { DateTime } from "luxon"
import { array, lazy, number, object, string } from "yup"

export const IncomeSchema = object().shape({
    id: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .optional(),
    value: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .min(1, "required")
        .required("value_required")
        .positive("invalid_value"),
    currencyId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("required")
        .min(0),
    date: string()
        .required("required")
        .test((date: string | undefined) => {
            if (date == null) return false

            const newDate = DateTime.fromISO(date)
            return newDate.isValid && DateTime.now().startOf("day") >= newDate.startOf("day")
        }),
    paymentMethod: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("required"),
    referenceId: lazy((v) => {
        if (v === "" || v == null) return number().optional()
        return number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("required_referenceId")
    }),
    files: array().required("required"),
})
