import { CustomDataGrid, Label, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useMemo } from "react"
import { Box, Button } from "@mui/material"
import { invoicingTypeAtom } from "../../../utils/atom"
import { setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { dispatch, useSelector } from "../../../redux/store"
import { InvoicingDryRun, useInvoicingDryRunInvoices } from "../../../queries/invoicing-configuration"
import NoDataPage from "../../../components/NoDataPage"
import { GridColDef } from "@mui/x-data-grid-pro"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { useIntl } from "react-intl"
import { DateTime } from "luxon"
import { getDryRunGeneratedStatusColor } from "./utils"
import { useSetAtom } from "jotai"

export default function DryRunInvoicingView() {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const setInvoicingType = useSetAtom(invoicingTypeAtom)
    const { data: dryRunInvoices, isLoading: areDryRunInvoicesLoading } = useInvoicingDryRunInvoices(editingItem?.invoiceCompanyId)
    const intl = useIntl()
    const { data: currencyData } = useCurrencyQueryFn()

    const closeModal = useCallback(() => {
        setInvoicingType(undefined)
        dispatch(setEditingItem(undefined))
        dispatch(setModalOpen(undefined))
    }, [modalOpen, dispatch])

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || currencyId == null) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData, intl],
    )

    const columns = useMemo(() => {
        const columns: GridColDef<InvoicingDryRun>[] = [
            {
                field: "propertyName",
                type: "string",
                minWidth: 120,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("propertyName"),
            },
            {
                field: "clientName",
                type: "string",
                minWidth: 140,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("clientName"),
            },
            {
                field: "productName",
                type: "string",
                minWidth: 120,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("productName"),
            },
            {
                field: "productDescription",
                type: "string",
                minWidth: 160,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("productDescription"),
            },
            {
                field: "value",
                type: "number",
                width: 160,
                headerAlign: "center",
                align: "center",
                headerName: translate("value"),
                valueFormatter: (value, row) => {
                    return getAmount(value, row.currencyId as number)
                },
            },
            {
                field: "date",
                type: "date",
                width: 140,
                headerAlign: "center",
                align: "center",
                headerName: translate("date"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime)?.toLocaleString(DateTime.DATE_SHORT) ?? "-",
            },
            {
                field: "dueDate",
                type: "date",
                width: 140,
                headerAlign: "center",
                align: "center",
                headerName: translate("due.date"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime)?.toLocaleString(DateTime.DATE_SHORT) ?? "-",
            },
            {
                field: "status",
                type: "string",
                minWidth: 100,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("status"),
                renderCell: (v) => {
                    return (
                        <Label color={getDryRunGeneratedStatusColor(v.value)} style={{ textTransform: "none" }}>
                            {translate(`dry-run-status-${v.value as number}`)}
                        </Label>
                    )
                },
            },
        ]

        return columns
    }, [translate, getAmount])

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingX: 0,
                paddingTop: 2,
                gap: 2,
                flexDirection: "column",
            }}
        >
            <CustomDataGrid
                columns={columns}
                gridId='DryRunInvoicingView'
                rows={dryRunInvoices ?? []}
                idKey={"propertyName"}
                getRowId={(row) => row.propertyName}
                components={{
                    noRowsOverlay: () => <NoDataPage onAddNewItem={undefined} icon={""} noDataText={"no_dry_run_configuration"} />,
                }}
                isLoading={areDryRunInvoicesLoading}
                rowHeight={50}
                gridHeight={400}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", paddingX: 0, paddingTop: 2 }}>
                <Button color={"primary"} onClick={closeModal} fullWidth={isMobile} variant={isMobile ? "contained" : "text"}>
                    {translate("cancel")}
                </Button>
            </Box>
        </Box>
    )
}
