import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query"
import { ComplexQueryIDs } from "../hooks/useQueryInvalidator"
import Api from "../api/Api"
import { useSelector } from "../redux/store"
import { DateTime } from "luxon"
import { GridSortModel } from "@mui/x-data-grid-pro"
import { sum } from "lodash"
import { AppContext, useIsMobile } from "rentzz"
import { useMemo } from "react"
import { PermissionType, usePermissions } from "../hooks/usePermissions"

export interface NotesBase {
    propertyId: number
    noteId: number
    title: string
    text: string
    isVisibleToTenant: boolean
}

export interface NotesDto extends NotesBase {
    addedAt: string
    updatedAt: string
}

export interface Notes extends NotesBase {
    addedAt: DateTime
    updatedAt: DateTime
}

export interface NotesResponseBase {
    count: number
}

export interface NotesResponseDto extends NotesResponseBase {
    items: NotesDto[]
}
export interface NotesResponse extends NotesResponseBase {
    items: Notes[]
}

export const useNotesQuery = (page: number, pageSize: number, sortingColumns: GridSortModel) => {
    const { currentPropertyId, context } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Notes)

    return useQuery({
        queryKey: [ComplexQueryIDs.Notes, { propertyId: currentPropertyId, page, pageSize, sortingColumns, context }],
        queryFn: Api.fetchNotes,
        staleTime: Infinity,
        enabled: !!currentPropertyId,
        select: (data) => {
            return {
                ...data,
                items: data.items.map((note) => {
                    return {
                        ...note,
                        canWrite: context !== AppContext.Tenant && (editablePropertiesIds?.includes(note.propertyId) ?? false),
                        canDelete: context !== AppContext.Tenant && (deletablePropertiesIds?.includes(note.propertyId) ?? false),
                    }
                }),
            }
        },
    })
}

export const useInfiniteNotesQueryFn = (sortingColumns?: GridSortModel) => {
    const { currentPropertyId, context } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Notes)

    return useInfiniteQuery({
        initialPageParam: 0,
        queryKey: [ComplexQueryIDs.Notes, { propertyId: currentPropertyId, sortingColumns, context }],
        queryFn: Api.fetchInfiniteNotes,
        staleTime: Infinity,
        enabled: !!currentPropertyId,
        select: (data) => {
            return {
                pages: data.pages.map((e) => {
                    return {
                        count: e.count,
                        items: e.items.map((i) => ({
                            ...i,
                            canWrite: editablePropertiesIds?.includes(i.propertyId) ?? false,
                            canDelete: deletablePropertiesIds?.includes(i.propertyId) ?? false,
                        })),
                    }
                }),
                pageParams: data.pageParams,
            }
        },
        getNextPageParam: (_, pages) => {
            const allItems = sum(pages.flatMap((p) => p.items.length))
            if (allItems === pages[0].count) {
                return undefined
            }
            return allItems
        },
    })
}

export const getNoteByIdQueryFn = (
    noteId?: number,
    sortingColumns?: GridSortModel,
): (Notes & { canWrite: boolean; canDelete: boolean }) | undefined => {
    const queryClient = useQueryClient()
    const isMobile = useIsMobile()
    const { currentPropertyId, context } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Incomes, currentPropertyId)

    return useMemo(() => {
        if (isMobile) {
            const notes = queryClient.getQueryData([ComplexQueryIDs.Notes, { propertyId: currentPropertyId, sortingColumns, context }]) as
                | {
                      pages: NotesResponse[]
                  }
                | undefined
            const arrayOfNotes = notes?.pages.flatMap((r) => r.items) as (Notes & { canWrite: boolean; canDelete: boolean })[]

            const note = arrayOfNotes?.find((note) => note.noteId == noteId)
            if (note) {
                return {
                    ...note,
                    canWrite: editablePropertiesIds?.includes(note.propertyId) ?? false,
                    canDelete: deletablePropertiesIds?.includes(note.propertyId) ?? false,
                }
            } else return note ?? undefined
        }
        const notesPages = queryClient.getQueriesData({
            queryKey: [ComplexQueryIDs.Notes, { propertyId: currentPropertyId }],
        })

        const arrayOfNotes = (notesPages?.flatMap((noteWithKey) => noteWithKey[1]) as NotesResponse[])?.flatMap((r) => r?.items) as (Notes & {
            canWrite: boolean
            canDelete: boolean
        })[]
        const note = arrayOfNotes?.find((note) => note.noteId == noteId)
        if (note) {
            return {
                ...note,
                canWrite: editablePropertiesIds?.includes(note.propertyId) ?? false,
                canDelete: deletablePropertiesIds?.includes(note.propertyId) ?? false,
            }
        } else return note ?? undefined
    }, [queryClient, isMobile, noteId])
}
