import { array, boolean, number, object } from "yup"

export const RentDetailsSchema = object().shape({
    value: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("required")
        .min(1, "minimum_price")
        .positive("invalid_value"),
    securityDeposit: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .positive("invalid_value"),
    paymentPeriod: array().required("required").min(1, "payment_period_required"),
    currencyId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("required"),
    automaticRentExpenseCurrencyId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .optional(),
    securityDepositCurrencyId: number().transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value)),
    isRentExpenseAutomatic: boolean().required("required"),
    acceptPartialPayments: boolean(),
})
