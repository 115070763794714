import { array, boolean, lazy, number, object, string } from "yup"
import { DateTime } from "luxon"

export const ExpensesSchema = (currentGroupId?: number) =>
    object().shape({
        id: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .optional(),
        rentingPeriodId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .optional(),
        name: string().required("required"),
        assignee: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("required")
            .min(0)
            .max(1),
        date: string()
            .required("required")
            .test((date: string | undefined) => {
                if (date == null) return false
                const newDate = DateTime.fromISO(date)

                return newDate.isValid
            }),
        dueDate: string()
            .required("required")
            .test((date: string | undefined) => {
                if (date == null) return false

                const newDate = DateTime.fromISO(date)
                return newDate.isValid
            }),
        value: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("required"),
        files: array().required(),
        associatedProviderId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .optional(),
        propertyId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .test("test", "required", function (value) {
                if (currentGroupId) {
                    return true
                }
                return value !== 0
            }),
        labelId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .optional(),
        currencyId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("required"),
        shouldNotifyOtherParty: boolean().optional(),
        shouldCreateInvoice: boolean().optional(),
        invoicingData: object().optional(),
        ___: number().optional(),
        paymentType: lazy((v) => {
            if (typeof v === "string") return string().required("required")

            return number()
                .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
                .required("required")
                .min(0)
                .max(4)
        }),
        groupId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .test("test", "required", function (value) {
                if (!currentGroupId) {
                    return true
                }
                return value !== 0
            }),
    })

export const ReinvoicingSchema = object().shape({
    invoiceDate: string()
        .required("required")
        .test((date: string | undefined) => {
            if (date == null) return false
            const newDate = DateTime.fromISO(date)

            return newDate.isValid
        }),
    invoiceDueDate: string()
        .required()
        .test((date: string | undefined) => {
            if (date == null) return false

            const newDate = DateTime.fromISO(date)
            return newDate.isValid
        }),
    modifyExpenseWithInvoiceData: boolean().required("required"),
})

export const AddCardPaymentDataSchema = object().shape({
    country: string().required("required"),
    county: string().required("required"),
    city: string().required("required"),
    address: string().required("required"),
    zipCode: string().required("required"),
    phoneNumber: string().required("required"),
})
