import { ReactNode, useEffect, useMemo, useState } from "react"
import Api from "./api/Api"
import { logoAtom, ThemeProvider } from "rentzz"
import { UserFlags } from "./queries/userData"
import { useAtom } from "jotai/index"
import { tracker } from "./index"
import { useFeatureIsOn, useFeatureValue, useGrowthBook } from "@growthbook/growthbook-react"

interface ConfigurationProviderProps {
    children: ReactNode
}
const ConfigurationProvider = ({ children }: ConfigurationProviderProps) => {
    const [logoAtomValue, setLogoAtom] = useAtom(logoAtom)
    const themeInfoColor = useFeatureValue(UserFlags.ThemeInfo, {} as any)
    const themeSuccessColor = useFeatureValue(UserFlags.ThemeSuccess, {} as any)
    const themeWarningColor = useFeatureValue(UserFlags.ThemeWarning, {} as any)
    const themeSecondaryColor = useFeatureValue(UserFlags.ThemeSecondary, {} as any)
    const themeErrorColor = useFeatureValue(UserFlags.ThemeError, {} as any)
    const themePrimaryColor = useFeatureValue(UserFlags.ThemePrimary, {} as any)
    const logoDimensions = useFeatureValue(UserFlags.Logo, {} as any)
    const backendPath = useFeatureValue(UserFlags.BackendPath, {} as any)
    const isOpenReplayEnabled = useFeatureIsOn(UserFlags.OpenReplay as string)
    const growthbook = useGrowthBook()
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        if (
            Object.keys(themeInfoColor).length > 0 &&
            Object.keys(themeSuccessColor).length > 0 &&
            Object.keys(themeWarningColor).length > 0 &&
            Object.keys(themeErrorColor).length > 0 &&
            Object.keys(themeSecondaryColor).length > 0 &&
            Object.keys(themePrimaryColor).length > 0
        ) {
            window.themeInfoColor = themeInfoColor
            window.themeSuccessColor = themeSuccessColor
            window.themeWarningColor = themeWarningColor
            window.themeErrorColor = themeErrorColor
            window.themeSecondaryColor = themeSecondaryColor
            window.themePrimaryColor = themePrimaryColor
            setIsReady(true)
        }
    }, [themeInfoColor, themeSuccessColor, themeWarningColor, themeErrorColor, themeSecondaryColor, themePrimaryColor])

    const style = useMemo(() => {
        return `/* Track */
            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: ${themePrimaryColor.main};
                border-radius: 10px;
            }
            
            ::-webkit-scrollbar-track {
                background: ${themePrimaryColor.lighter};
                border-radius: 10px;
            }

            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
                background: ${themePrimaryColor.dark};
            }
            `
    }, [themePrimaryColor])

    useEffect(() => {
        // Load features asynchronously when the app renders
        growthbook?.loadFeatures()
    }, [])

    useEffect(() => {
        if (import.meta.env.PROD) {
            Api.API = backendPath as string
            if (isOpenReplayEnabled) tracker.start()
        } else {
            Api.API = import.meta.env.VITE_API_URL
        }
    }, [backendPath, isOpenReplayEnabled])

    useEffect(() => {
        if (!logoDimensions.logoMenuWidth) return
        setLogoAtom({
            loading: {
                width: Number(logoDimensions.logoLoadingWidth),
                height: Number(logoDimensions.logoLoadingHeight),
            },
            menu: {
                width: Number(logoDimensions.logoMenuWidth),
                height: Number(logoDimensions.logoMenuHeight),
            },
        })
    }, [logoDimensions])

    if (!growthbook?.ready || logoAtomValue.loading == null || !isReady) return null

    return (
        <ThemeProvider>
            <style>{style}</style>
            {children}
        </ThemeProvider>
    )
}

export default ConfigurationProvider
