import { CustomTabs, LoadingIcon, Page, useLocales } from "rentzz"
import { Box, Container, Tab } from "@mui/material"
import { a11yProps, ContentStyle, TabPanel } from "../../../pages/PropertyDetails"
import Expenses from "../../expenses/Expenses"
import Income from "../../income/IncomePage"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useParams } from "react-router"
import { useSelector } from "../../../redux/store"
import GroupPropertiesPage from "./GroupPropertiesPage"
import { InPlaceEditorComponent } from "@syncfusion/ej2-react-inplace-editor"
import { ActionEventArgs } from "@syncfusion/ej2-inplace-editor"
import * as jsonpatch from "fast-json-patch"
import DashboardGroup from "../DashboardGroup"
import { updateGroupMutation } from "../../../mutations/groups"
import { useUserGroupsQueryFn } from "../../../queries/groups"
import PropertyHistory from "./PropertyHistory"
import GroupAccessPage from "./GroupAccessPage"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../queries/userData"

export enum GroupPages {
    Dashboard,
    Properties,
    Expenses,
    Incomes,
    PropertyHistory,
    GroupAccess,
}

export default function GroupSection() {
    const navigate = useNavigate()
    const { translate } = useLocales()
    const { page } = useParams()
    const { mutateAsync: updateGroup } = updateGroupMutation()
    const { currentGroupId } = useSelector((state) => state.appState)
    const [activePage, setActivePage] = useState<GroupPages>(GroupPages.Dashboard)
    const { data: groups } = useUserGroupsQueryFn()
    const currentGroup = useMemo(() => groups?.find((g) => g.id === currentGroupId), [currentGroupId])
    const model = useMemo(() => ({ placeholder: translate("enter_group_name") }), [translate])
    const descriptionModel = useMemo(() => ({ placeholder: translate("enter_group_description") }), [translate])
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())

    useEffect(() => {
        switch (page) {
            case "properties":
                setActivePage(GroupPages.Properties)
                break
            case "expenses":
                setActivePage(GroupPages.Expenses)
                break
            case "incomes":
                setActivePage(GroupPages.Incomes)
                break
            case "dashboard":
                setActivePage(GroupPages.Dashboard)
                break
            case "occupancy":
                setActivePage(GroupPages.PropertyHistory)
                break
            case "access":
                setActivePage(GroupPages.GroupAccess)
                break
            default:
                setActivePage(GroupPages.Properties)
        }
    }, [page])

    const onNameChange = useCallback(
        async (action: ActionEventArgs) => {
            if (currentGroup == null) return
            if (currentGroup.name === action.value) return
            const operations = jsonpatch.compare({ name: currentGroup.name }, { name: action.value })

            await updateGroup({
                groupId: currentGroup.id,
                operations,
            })
        },
        [currentGroup],
    )

    const onDescriptionChange = useCallback(
        async (action: ActionEventArgs) => {
            if (currentGroup == null) return
            if (currentGroup.description === action.value) return
            const operations = jsonpatch.compare({ description: currentGroup.description }, { description: action.value })

            await updateGroup({
                groupId: currentGroup.id,
                operations,
            })
        },
        [currentGroup],
    )

    const handleChange = useCallback(
        (event: React.SyntheticEvent, newValue: GroupPages | undefined) => {
            switch (newValue) {
                case GroupPages.Dashboard:
                    navigate(`/groups/${currentGroupId}/dashboard`)
                    break
                case GroupPages.Properties:
                    navigate(`/groups/${currentGroupId}/properties`)
                    break
                case GroupPages.Expenses:
                    navigate(`/groups/${currentGroupId}/expenses`)
                    break
                case GroupPages.Incomes:
                    navigate(`/groups/${currentGroupId}/incomes`)
                    break
                case GroupPages.PropertyHistory:
                    navigate(`/groups/${currentGroupId}/occupancy`)
                    break
                case GroupPages.GroupAccess:
                    navigate(`/groups/${currentGroupId}/access`)
                    break
            }
        },
        [navigate, currentGroupId],
    )

    if (currentGroup == null) return <LoadingIcon />

    return (
        <Page title={translate("group_details")}>
            <Box display={"flex"} flexDirection={"column"} paddingX={2} gap={1}>
                <Box
                    sx={{
                        ["& .e-inplaceeditor .e-editable-value-wrapper .e-editable-value "]: {
                            typography: "subtitle1",
                        },
                    }}
                >
                    <InPlaceEditorComponent
                        model={model}
                        editableOn={"EditIconClick"}
                        type='Text'
                        name={"GroupName"}
                        value={currentGroup.name}
                        validationRules={{ GroupName: { required: true, minLength: 3 } }}
                        popupSettings={{ model: { position: "BottomCenter" } }}
                        actionSuccess={onNameChange}
                    />
                </Box>
                <Box
                    sx={{
                        ["& .e-inplaceeditor .e-editable-value-wrapper .e-editable-value "]: {
                            typography: "body2",
                        },
                    }}
                >
                    <InPlaceEditorComponent
                        model={descriptionModel}
                        editableOn={"EditIconClick"}
                        type='Text'
                        name={"GroupDescription"}
                        value={currentGroup.description}
                        validationRules={{ GroupDescription: { required: true, minLength: 3 } }}
                        popupSettings={{ model: { position: "BottomCenter" } }}
                        actionSuccess={onDescriptionChange}
                    />
                </Box>
            </Box>
            <Container maxWidth={false}>
                <ContentStyle>
                    <Box sx={{ width: "100%" }}>
                        <Box sx={{ borderColor: "divider" }}>
                            <CustomTabs value={activePage} onChange={handleChange} allowScrollButtonsMobile scrollButtons='auto' variant='scrollable'>
                                <Tab iconPosition='end' label={translate("dashboard")} {...a11yProps(0)} />
                                <Tab iconPosition='end' label={translate("properties")} {...a11yProps(1)} />
                                <Tab iconPosition='end' label={translate("expenses_title")} {...a11yProps(2)} />
                                <Tab iconPosition='end' label={translate("income")} {...a11yProps(3)} />
                                <Tab iconPosition='end' label={translate("property_occupancy")} {...a11yProps(4)} />
                                {(isWhitelabel || import.meta.env.DEV) && <Tab label={translate("group_access")} {...a11yProps(4)} />}
                            </CustomTabs>
                        </Box>
                        <TabPanel value={activePage} index={GroupPages.Dashboard}>
                            <DashboardGroup />
                        </TabPanel>
                        <TabPanel value={activePage} index={GroupPages.Properties}>
                            <GroupPropertiesPage />
                        </TabPanel>
                        <TabPanel value={activePage} index={GroupPages.Expenses}>
                            <Page title={translate("tenants")} propertyName={translate("expenses.expenses")}>
                                <Expenses />
                            </Page>
                        </TabPanel>
                        <TabPanel value={activePage} index={GroupPages.Incomes}>
                            <Income />
                        </TabPanel>
                        <TabPanel value={activePage} index={GroupPages.PropertyHistory}>
                            <PropertyHistory />
                        </TabPanel>
                        {(isWhitelabel || import.meta.env.DEV) && (
                            <TabPanel value={activePage} index={GroupPages.GroupAccess}>
                                <GroupAccessPage />
                            </TabPanel>
                        )}
                    </Box>
                </ContentStyle>
            </Container>
        </Page>
    )
}
