import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query"
import { ComplexQueryIDs, SimpleQueryIDs } from "../../hooks/useQueryInvalidator"
import Api from "../../api/Api"
import { useSelector } from "../../redux/store"
import { AppContext, useIsMobile } from "rentzz"
import { sum } from "lodash"
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro"
import { useRentingPeriodDetails } from "../tenants"
import { PropertyExpense, PropertyExpenseResponse, RecurringExpense, RecurringExpenseResponse } from "./types"
import { AppModals } from "../../redux/slices/App"
import { PermissionType, usePermissions } from "../../hooks/usePermissions"
import { useUserPropertiesQuery } from "../userData"
import { IncomeStatus } from "../../redux/slices/AddProperty"
import { useMemo } from "react"
import { useUserGroupsQueryFn } from "../groups"

export const useExpensesQueryFn = (
    page: number,
    pageSize: number,
    pending: boolean,
    sortingColumns: GridSortModel,
    filterModel?: GridFilterModel,
) => {
    const { context, currentPropertyId, currentGroupId, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Expenses, rentingPeriodDetails?.propertyId)
    const { data: properties } = useUserPropertiesQuery()
    const { data: groups } = useUserGroupsQueryFn()

    const propertiesIds = useMemo(() => {
        if (currentRentingPeriodId && rentingPeriodDetails) return [rentingPeriodDetails.propertyId]
        if (currentPropertyId) return [currentPropertyId]
        if (currentGroupId) return groups?.find((g) => g.id === currentGroupId)?.properties.map((p) => p.propertyId)
        return properties?.map((p) => p.id)
    }, [properties, currentRentingPeriodId, rentingPeriodDetails, currentPropertyId, currentGroupId])

    return useQuery({
        queryKey: [
            ComplexQueryIDs.Expenses,
            {
                properties: propertiesIds,
                page,
                pageSize,
                context,
                sortingColumns,
                rentingPeriodId: rentingPeriodDetails?.id,
                pending,
                filterModel,
            },
        ],
        queryFn: Api.fetchExpenses,
        staleTime: Infinity,
        enabled: (context === AppContext.Tenant && currentPropertyId != null) || context === AppContext.Owner,
        select: (data) => {
            return {
                count: data.count,
                items: data.items.map((i) => ({
                    ...i,
                    canWrite: editablePropertiesIds?.includes(i.propertyId) ?? false,
                    canDelete: deletablePropertiesIds?.includes(i.propertyId) ?? false,
                })),
            }
        },
    })
}

export const useExpenseLinkedIncomesQuery = (incomeIds: number[] | undefined, expenseId: number | undefined) => {
    const { context, currentPropertyId } = useSelector((state) => state.appState)
    const { data: userProperties } = useUserPropertiesQuery()
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Expenses)

    return useQuery({
        queryKey: [
            ComplexQueryIDs.ExpenseIncomes,
            {
                expenseId,
                incomeIds,
                propertyId: currentPropertyId,
            },
        ],
        queryFn: Api.fetchExpenseIncomes,
        staleTime: Infinity,
        enabled:
            ((context === AppContext.Tenant && currentPropertyId != null) || context === AppContext.Owner) && !!incomeIds && incomeIds.length > 0,
        select: (data) => {
            if (userProperties == null && context === AppContext.Owner) return []
            return data.map((i) => ({
                ...i,
                canWrite:
                    (editablePropertiesIds?.includes(i.propertyId) ?? false) || (context === AppContext.Owner && i.status !== IncomeStatus.Accepted),
                canDelete: deletablePropertiesIds?.includes(i.propertyId) ?? false,
            }))
        },
    })
}

export const useSingleExpenseQuery = (expenseId?: number) => {
    const { currentPropertyId, context, modalOpen, currentRentingPeriodId } = useSelector((state) => state.appState)
    const queryClient = useQueryClient()
    const isMobile = useIsMobile()
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Expenses, rentingPeriodDetails?.propertyId)

    return useQuery({
        queryKey: [ComplexQueryIDs.Expense, { expenseId }],
        queryFn: async () => {
            let toReturn: (PropertyExpense & { canWrite: boolean; canDelete: boolean }) | undefined | null
            if (isMobile) {
                const expenses = queryClient.getQueryData([
                    ComplexQueryIDs.Expenses,
                    { propertyId: currentPropertyId ?? rentingPeriodDetails?.propertyId, rentingPeriodId: rentingPeriodDetails?.id },
                ]) as
                    | {
                          pages: PropertyExpenseResponse[]
                      }
                    | undefined
                const arrayOfExpenses = expenses?.pages.flatMap((r) => r.items)
                const expense = arrayOfExpenses?.find((expense) => expense.id == expenseId)
                if (expense) {
                    toReturn = {
                        ...expense,
                        canWrite: editablePropertiesIds?.includes(expense.propertyId) ?? false,
                        canDelete: deletablePropertiesIds?.includes(expense.propertyId) ?? false,
                    }
                } else toReturn = expense
            } else {
                const expensesPages = queryClient.getQueriesData({
                    queryKey: [ComplexQueryIDs.Expenses, { propertyId: currentPropertyId }],
                })

                const arrayOfExpenses = (expensesPages?.flatMap((expenseWithKey) => expenseWithKey[1]) as PropertyExpenseResponse[])?.flatMap(
                    (r) => r?.items,
                )

                const expense = arrayOfExpenses.find((expense) => expense?.id == expenseId)
                if (expense) {
                    toReturn = {
                        ...expense,
                        canWrite: editablePropertiesIds?.includes(expense.propertyId) ?? false,
                        canDelete: deletablePropertiesIds?.includes(expense.propertyId) ?? false,
                    }
                } else toReturn = expense
            }

            const expense =
                toReturn ??
                (context === AppContext.Owner ? await Api.getExpense(expenseId!) : await Api.getTenantExpense(expenseId!, currentPropertyId!)) ??
                null

            if (expense) {
                toReturn = {
                    ...expense,
                    canWrite: editablePropertiesIds?.includes(expense.propertyId) ?? false,
                    canDelete: deletablePropertiesIds?.includes(expense.propertyId) ?? false,
                }
            } else toReturn = null

            return toReturn
        },

        staleTime: Infinity,
        enabled:
            !!expenseId &&
            modalOpen != null &&
            [
                AppModals.ExpenseDetails,
                AppModals.EditExpense,
                AppModals.AddExpense,
                AppModals.DeleteExpense,
                AppModals.LinkExpenseToIncomeAsOwner,
                AppModals.LinkExpenseToIncomeAsTenant,
                AppModals.AddPayment,
                AppModals.AddIncome,
                AppModals.EditIncome,
                AppModals.HandleIncomeStatus,
                AppModals.UpdateExpenseBalanceStatus,
                AppModals.ExpensePayment,
            ].includes(modalOpen),
    })
}

export const useInfiniteExpensesQueryFn = (pending: boolean, sortingModel?: GridSortModel, propertyId?: number, filterModel?: GridFilterModel) => {
    const { context, currentPropertyId, currentGroupId, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Expenses, rentingPeriodDetails?.propertyId)
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: groups } = useUserGroupsQueryFn()

    const propertiesIds = useMemo(() => {
        if (propertyId) return [propertyId]
        if (currentRentingPeriodId && rentingPeriodDetails) return [rentingPeriodDetails.propertyId]
        if (currentPropertyId) return [currentPropertyId]
        if (currentGroupId) return groups?.find((g) => g.id === currentGroupId)?.properties.map((p) => p.propertyId)
        return userProperties?.map((p) => p.id)
    }, [rentingPeriodDetails, currentPropertyId, currentGroupId, userProperties, currentRentingPeriodId, propertyId])

    return useInfiniteQuery({
        initialPageParam: 0,
        queryKey: [
            ComplexQueryIDs.Expenses,
            {
                properties: propertiesIds,
                sortingModel,
                rentingPeriodId: rentingPeriodDetails?.id,
                pending,
                filterModel,
            },
        ],
        queryFn: Api.fetchInfiniteExpenses,
        staleTime: Infinity,
        enabled: ((context === AppContext.Tenant && currentPropertyId != null) || context === AppContext.Owner) && (propertiesIds?.length ?? 0) > 0,
        getNextPageParam: (_, pages) => {
            const allItems = sum(pages.flatMap((p) => p.items.length))
            if (allItems === pages[0].count) {
                return undefined
            }
            return allItems
        },
        select: (data) => {
            return {
                pages: data.pages.map((e) => {
                    return {
                        count: e.count,
                        items: e.items.map((i) => ({
                            ...i,
                            canWrite: editablePropertiesIds?.includes(i.propertyId) ?? false,
                            canDelete: deletablePropertiesIds?.includes(i.propertyId) ?? false,
                        })),
                    }
                }),
                pageParams: data.pageParams,
            }
        },
    })
}

export const useRecurringExpensesQuery = (page: number, pageSize: number, sortingColumns: GridSortModel) => {
    const { context, currentPropertyId } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.RecurringExpense)

    return useQuery({
        queryKey: [
            ComplexQueryIDs.RecurringExpenses,
            {
                propertyId: currentPropertyId,
                page,
                pageSize,
                sortingColumns,
            },
        ],
        queryFn: Api.fetchRecurringExpenses,
        staleTime: Infinity,
        enabled: context === AppContext.Owner,
        select: (data) => {
            return {
                count: data.count,
                items: data.items.map((i) => ({
                    ...i,
                    canWrite: editablePropertiesIds?.includes(i.propertyId) ?? false,
                    canDelete: deletablePropertiesIds?.includes(i.propertyId) ?? false,
                })),
            }
        },
    })
}

export const useInfiniteRecurringExpensesQueryFn = (sortingModel?: GridSortModel) => {
    const { context, currentPropertyId } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.RecurringExpense)

    return useInfiniteQuery({
        initialPageParam: 0,
        queryKey: [
            ComplexQueryIDs.RecurringExpenses,
            {
                propertyId: currentPropertyId,
                sortingModel,
            },
        ],
        queryFn: Api.fetchInfiniteRecurringExpenses,
        staleTime: Infinity,
        enabled: context === AppContext.Owner,
        getNextPageParam: (_, pages) => {
            const allItems = sum(pages.flatMap((p) => p.items.length))
            if (allItems === pages[0].count) {
                return undefined
            }
            return allItems
        },
        select: (data) => {
            return {
                pages: data.pages.map((e) => {
                    return {
                        count: e.count,
                        items: e.items.map((i) => ({
                            ...i,
                            canWrite: editablePropertiesIds?.includes(i.propertyId) ?? false,
                            canDelete: deletablePropertiesIds?.includes(i.propertyId) ?? false,
                        })),
                    }
                }),
                pageParams: data.pageParams,
            }
        },
    })
}

export const useSingleRecurringExpenseQuery = (expenseId?: string, sortingModel?: GridSortModel) => {
    const { currentPropertyId } = useSelector((state) => state.appState)
    const queryClient = useQueryClient()
    const isMobile = useIsMobile()
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.RecurringExpense)

    return useQuery({
        queryFn: async () => {
            let toReturn: (RecurringExpense & { canWrite: boolean; canDelete: boolean }) | undefined | null
            if (isMobile) {
                const expenses = queryClient.getQueryData([ComplexQueryIDs.RecurringExpenses, { propertyId: currentPropertyId, sortingModel }]) as
                    | {
                          pages: RecurringExpenseResponse[]
                      }
                    | undefined

                const arrayOfExpenses = expenses?.pages.flatMap((r) => r.items)
                const recurringExpense = arrayOfExpenses?.find((expense) => expense.id == expenseId)

                if (recurringExpense) {
                    toReturn = {
                        ...recurringExpense,
                        canWrite: editablePropertiesIds?.includes(recurringExpense.propertyId) ?? false,
                        canDelete: deletablePropertiesIds?.includes(recurringExpense.propertyId) ?? false,
                    }
                } else toReturn = recurringExpense
            } else {
                const expensesPages = queryClient.getQueriesData({
                    queryKey: [ComplexQueryIDs.RecurringExpenses, { propertyId: currentPropertyId }],
                })

                const arrayOfExpenses = (expensesPages?.flatMap((expenseWithKey) => expenseWithKey[1]) as RecurringExpenseResponse[])?.flatMap(
                    (r) => r?.items,
                )
                // search in all available pages for our expenseId
                const recurringExpense = arrayOfExpenses.find((expense) => expense?.id == expenseId)
                if (recurringExpense) {
                    toReturn = {
                        ...recurringExpense,
                        canWrite: editablePropertiesIds?.includes(recurringExpense.propertyId) ?? false,
                        canDelete: deletablePropertiesIds?.includes(recurringExpense.propertyId) ?? false,
                    }
                }
            }

            return toReturn ?? null
        },
        queryKey: [SimpleQueryIDs.RecurringExpense, { expenseId }],
        staleTime: Infinity,
        enabled: !!expenseId,
    })
}

export const useSinglePendingExpenseQueryFn = (expenseId: number) => {
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.PendingExpenses)
    const { modalOpen } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [SimpleQueryIDs.PendingExpense, { expenseId }],
        queryFn: Api.getPendingExpense,
        staleTime: Infinity,
        enabled:
            !!expenseId &&
            modalOpen != null &&
            [
                AppModals.DeletePendingExpense,
                AppModals.PendingExpenseDetails,
                AppModals.PendingExpenseStatus,
                AppModals.DeactivateSendingExpenseToTenant,
            ].includes(modalOpen),
        select: (data) => {
            return {
                ...data,
                canWrite: editablePropertiesIds?.includes(data.propertyId) ?? false,
                canDelete: deletablePropertiesIds?.includes(data.propertyId) ?? false,
            }
        },
    })
}

export const getExpensesReportSummaryQueryFn = () => {
    const { currentPropertyId, currentGroupId, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { data: properties } = useUserPropertiesQuery()
    const { data: groups } = useUserGroupsQueryFn()

    const propertiesIds = useMemo(() => {
        if (rentingPeriodDetails) return [rentingPeriodDetails.propertyId]
        if (currentPropertyId) return [currentPropertyId]
        if (currentGroupId) return groups?.find((g) => g.id === currentGroupId)?.properties.map((p) => p.propertyId)
        return properties?.map((p) => p.id)
    }, [rentingPeriodDetails, currentPropertyId, currentGroupId, properties])

    return useQuery({
        queryKey: [ComplexQueryIDs.ExpenseSummaryReport, { properties: propertiesIds, currentRentingPeriodId }],
        queryFn: Api.getExpensesSummaryReport,
        staleTime: Infinity,
        enabled: (propertiesIds?.length ?? 0) > 0,
    })
}
