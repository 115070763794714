import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro"
import { useSelector } from "../redux/store"
import { PermissionType, usePermissions } from "../hooks/usePermissions"
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query"
import { ComplexQueryIDs } from "../hooks/useQueryInvalidator"
import { AppContext, useIsMobile } from "rentzz"
import Api from "../api/Api"
import { sum } from "lodash"
import { useMemo } from "react"
import { PropertyRevision, PropertyRevisionResponse } from "../redux/slices/AddProperty"

export const useRevisionsQueryFn = (page: number, pageSize: number, sortingColumns: GridSortModel, filterModel: GridFilterModel) => {
    const { context, currentPropertyId } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Revisions)

    return useQuery({
        queryKey: [
            ComplexQueryIDs.Revisions,
            {
                propertyId: currentPropertyId,
                page,
                pageSize,
                sortingColumns,
                filterModel,
            },
        ],
        queryFn: Api.fetchRevisions,
        staleTime: Infinity,
        enabled: context === AppContext.Owner,
        select: (data) => {
            return {
                count: data.count,
                items: data.items.map((i) => ({
                    ...i,
                    canWrite: editablePropertiesIds?.includes(i.propertyId) ?? false,
                    canDelete: deletablePropertiesIds?.includes(i.propertyId) ?? false,
                })),
            }
        },
    })
}

export const useInfiniteRevisionsQueryFn = (sortingColumns?: GridSortModel) => {
    const { currentPropertyId, context } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Revisions)

    return useInfiniteQuery({
        initialPageParam: 0,
        queryKey: [ComplexQueryIDs.Revisions, { propertyId: currentPropertyId, sortingColumns }],
        queryFn: Api.fetchInfiniteRevisions,
        staleTime: Infinity,
        enabled: context === AppContext.Owner,
        select: (data) => {
            return {
                pages: data.pages.map((e) => {
                    return {
                        count: e.count,
                        items: e.items.map((i) => ({
                            ...i,
                            canWrite: editablePropertiesIds?.includes(i.propertyId) ?? false,
                            canDelete: deletablePropertiesIds?.includes(i.propertyId) ?? false,
                        })),
                    }
                }),
                pageParams: data.pageParams,
            }
        },
        getNextPageParam: (_, pages) => {
            const allItems = sum(pages.flatMap((p) => p.items.length))
            if (allItems === pages[0].count) {
                return undefined
            }
            return allItems
        },
    })
}

export const getRevisionByIdQueryFn = (
    revisionId?: string,
    sortingColumns?: GridSortModel,
): (PropertyRevision & { canWrite: boolean; canDelete: boolean }) | undefined => {
    const queryClient = useQueryClient()
    const isMobile = useIsMobile()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Revisions)

    return useMemo(() => {
        if (isMobile) {
            const revisions = queryClient.getQueryData([ComplexQueryIDs.Revisions, { propertyId: currentPropertyId, sortingColumns }]) as
                | {
                      pages: PropertyRevisionResponse[]
                  }
                | undefined
            const arrayOfRevisions = revisions?.pages.flatMap((r) => r.items) as (PropertyRevision & { canWrite: boolean; canDelete: boolean })[]

            const revision = arrayOfRevisions?.find((revision) => revision?.id == revisionId)
            if (revision) {
                return {
                    ...revision,
                    canWrite: editablePropertiesIds?.includes(revision.propertyId) ?? false,
                    canDelete: deletablePropertiesIds?.includes(revision.propertyId) ?? false,
                }
            } else return revision ?? undefined
        }
        const revisionsPages = queryClient.getQueriesData({
            queryKey: [ComplexQueryIDs.Revisions, { propertyId: currentPropertyId }],
        })

        const arrayOfRevisions = (revisionsPages?.flatMap((revisionWithKey) => revisionWithKey[1]) as PropertyRevisionResponse[])?.flatMap(
            (r) => r?.items,
        ) as (PropertyRevision & {
            canWrite: boolean
            canDelete: boolean
        })[]
        const revision = arrayOfRevisions?.find((revision) => revision?.id == revisionId)

        if (revision) {
            return {
                ...revision,
                canWrite: editablePropertiesIds?.includes(revision.propertyId) ?? false,
                canDelete: deletablePropertiesIds?.includes(revision.propertyId) ?? false,
            }
        } else return revision ?? undefined
    }, [queryClient, isMobile, revisionId])
}

export const useTenantRevisionsQuery = () => {
    const { context, currentPropertyId, currentRentingPeriodId } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [
            ComplexQueryIDs.TenantRevisions,
            {
                rentingPeriodId: currentRentingPeriodId,
            },
        ],
        queryFn: Api.fetchTenantRevisions,
        staleTime: Infinity,
        enabled: context === AppContext.Tenant && !!currentPropertyId && !!currentRentingPeriodId,
    })
}

export const useRevisionObservationsQueryFn = (revisionId?: string | number) => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [
            ComplexQueryIDs.RevisionObservations,
            {
                revisionId,
            },
        ],
        queryFn: Api.fetchRevisionObservations,
        staleTime: Infinity,
        enabled: !!revisionId && context === AppContext.Owner,
    })
}

export const useTenantRevisionObservationsQueryFn = (revisionId?: string | number) => {
    const { context, currentRentingPeriodId } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [
            ComplexQueryIDs.TenantRevisionObservations,
            {
                revisionId,
                currentRentingPeriodId,
            },
        ],
        queryFn: Api.fetchTenantRevisionObservations,
        staleTime: Infinity,
        enabled: !!revisionId && context === AppContext.Tenant,
    })
}
